import React from 'react';
import Box from '../Box';
import useStyles from './useStyles';

const GameFrame: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box
      id="game-frame"
      // minHeight={{ xs: 714, md: 486 }}
      border="6px solid #000"
      borderRadius="50px"
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {children}
      <Box
        position="absolute"
        left={-6}
        top="50%"
        width={6}
        height={20}
        className={classes.hinge}
      />
      <Box
        position="absolute"
        right={-6}
        top="50%"
        width={6}
        height={20}
        className={classes.hinge}
      />
    </Box>
  );
};

export default GameFrame;
