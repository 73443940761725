import { makeStyles } from '@mui/styles';

export default makeStyles({
  flipper: {
    transformStyle: 'preserve-3d',
  },
  front: {
    backfaceVisibility: 'hidden',
    transform: 'rotateX(0)',
  },
  back: {
    backfaceVisibility: 'hidden',
    transform: 'rotateX(180deg) translateZ(200px)',
  },
});
