import React, { createContext, useEffect, useState } from 'react';
import { GameConfig } from '../../../../types';
import getCurrentConfig from '../../../firebase/quiz/getCurrentConfig';
import Snackbar from '../../Unknown/Snackbar';

interface ConfigContextProps {
  config: GameConfig;
}

export const ConfigContext = createContext<ConfigContextProps>(
  {} as ConfigContextProps,
);

export const ConfigContextProvider: React.FC = ({ children }) => {
  const [config, setConfig] = useState<ConfigContextProps['config']>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const delayMs = 5000;

  const onSnackbarClose = () => {
    setErrorMessage(null);
  };

  useEffect(() => {
    const getPrize = async () => {
      try {
        const data = await getCurrentConfig();
        if (data) {
          setConfig(data);
        }
      } catch (error) {
        if (error instanceof Error) {
          setErrorMessage(error.message);
        }
      }
    };

    getPrize();
  }, []);

  if (!config) {
    return (
      <>
        {errorMessage && (
          <Snackbar
            open={Boolean(errorMessage)}
            autoHideDuration={delayMs}
            onClose={onSnackbarClose}
            message={errorMessage}
          />
        )}
      </>
    );
  }

  return (
    <ConfigContext.Provider value={{ config }}>
      {children}
    </ConfigContext.Provider>
  );
};
