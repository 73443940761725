import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Dialog, { DialogProps } from '../Dialog';
import DialogTitle from '../DialogTitle';
import DialogActions from '../DialogActions';
import Button from '../Button';
import Box from '../Box';
import Typography from '../Typography';
import useStyles from './useStyles';

interface ConfirmModalProps extends DialogProps {
  message: React.ReactElement | string;
  confirmButtonLabel: React.ReactElement | string;
  cancelButtonLabel: React.ReactElement | string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  message,
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm,
  onCancel,
  ...dialogProps
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      {...dialogProps}
      fullScreen={isMobile}
      PaperProps={{ elevation: 0 }}
    >
      <Box p={{ xs: 7, md: 15 }} width={{ xs: 570, md: 852 }} maxWidth="100%">
        <DialogTitle>
          <Typography
            textAlign="center"
            fontSize={{ xs: 40, md: 55 }}
            lineHeight={{ xs: '47px', md: '64px' }}
            py={{ xs: 6, md: 10 }}
          >
            {message}
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Box
            mt={3}
            py={{ md: 5 }}
            width={{ xs: 320, sm: 420 }}
            maxWidth="100%"
          >
            <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={onConfirm}
                size={isMobile ? 'medium' : 'large'}
                fullWidth
              >
                {confirmButtonLabel}
              </Button>
            </Box>
            <Box
              mt={{ xs: 3, md: 10 }}
              width={{ xs: 320, sm: 420 }}
              maxWidth="100%"
            >
              <Button
                variant="contained"
                color="error"
                onClick={onCancel}
                size={isMobile ? 'medium' : 'large'}
                fullWidth
              >
                {cancelButtonLabel}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmModal;
