import {
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@firebase/firestore-types';

export default <T>(snapshot: QuerySnapshot<T>): T[] => {
  return snapshot.docs.map((doc: QueryDocumentSnapshot<T>) => ({
    id: doc.id,
    ...doc.data(),
  }));
};
