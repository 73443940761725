import { useFirebaseApp } from 'reactfire';
import firebase from 'firebase/app';

type FunctionName = 'endQuiz';

export default (): Record<FunctionName, firebase.functions.HttpsCallable> => {
  const firebaseApp = useFirebaseApp();

  const endQuiz = firebaseApp
    .functions('europe-west3')
    .httpsCallable('endQuiz');

  return {
    endQuiz,
  };
};
