import { defineMessages } from 'react-intl';

export default defineMessages({
  days: {
    id: 'Unknown.Countdown.days',
    defaultMessage: 'Days',
  },
  hours: {
    id: 'Unknown.Countdown.hours',
    defaultMessage: 'Hours',
  },
  minutes: {
    id: 'Unknown.Countdown.minutes',
    defaultMessage: 'Mins',
  },
  seconds: {
    id: 'Unknown.Countdown.seconds',
    defaultMessage: 'Sec',
  },
});
