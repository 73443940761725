import React from 'react';
import Box from '../Box';
import Link from '../Link';
import socialLinksData from './socialLinksData';

const SocialLinks: React.FC = () => {
  return (
    <Box display="flex">
      {socialLinksData.map(({ href, icon }, index) => {
        const isFirst = index === 0;

        return (
          <Link
            // eslint-disable-next-line react/no-array-index-key
            key={`link_${index}`}
            ml={isFirst ? 0 : { xs: 4, lg: 6 }}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
            color="primary.main"
          >
            <Box width={{ xs: 39, lg: 63 }} height={{ xs: 39, lg: 63 }}>
              {icon}
            </Box>
          </Link>
        );
      })}
    </Box>
  );
};

export default SocialLinks;
