import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/analytics';
import firebaseConfig from './firebaseConfig';

const app = firebase.initializeApp(firebaseConfig);
firebase.analytics();

if (process.env.REACT_APP_USE_FIREBASE_EMULATOR) {
  app.functions('europe-west3').useEmulator('localhost', 5001);
  app.firestore().useEmulator('localhost', 8080);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const firebaseToken = (global as any).FIREBASE_TOKEN;
if (firebaseToken) {
  app.auth().signInWithCustomToken(firebaseToken);
}

export default app;
