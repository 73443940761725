import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';
import { FormikValues, useFormik } from 'formik';
import messages from './messages';
import replaceValues from './replaceValues';
import getFormattedRange from './getFormattedRange';
import { DEFAULT_LOCALE } from '../../../common/constants';
import Dialog, { DialogProps } from '../../Unknown/Dialog';
import DialogTitle from '../../Unknown/DialogTitle';
import DialogActions from '../../Unknown/DialogActions';
import Button from '../../Unknown/Button';
import Box from '../../Unknown/Box';
import Typography from '../../Unknown/Typography';
import useStyles from './useStyles';
import IconButton from '../../Unknown/IconButton';
import { ExitIcon } from '../../Unknown/Icons';
import rulesImageDesktop from './rulesImageDesktop.png';
import rulesImageMob from './rulesImageMob.png';
import FormControlLabel from '../../Unknown/FormControlLabel';
import Checkbox from '../../Unknown/Checkbox';
import { ConfigContext } from '../ConfigContext';
import useDontShowRules from './useDontShowRules';

interface RulesPopUpProps extends DialogProps {
  onClose: () => void;
  // eslint-disable-next-line react/require-default-props
  onQuizStart?: () => Promise<void>;
  open: boolean;
}

const initialValues = {
  notShowRules: false,
};

const RulesPopUp: React.FC<RulesPopUpProps> = ({
  onClose,
  onQuizStart,
  open,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { paper, image } = useStyles();
  const intl = useIntl();
  const {
    config: {
      rulesText,
      byComplexity,
      successfulQuizPoints,
      notUsedBoosterPoints,
      secondLeftPoints,
    },
  } = useContext(ConfigContext);
  const { setDontShowRules } = useDontShowRules();
  const locale = intl.locale || DEFAULT_LOCALE;

  const onSubmit = (values: FormikValues) => {
    setDontShowRules(values.notShowRules);
    onClose();
    if (onQuizStart) onQuizStart();
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    onSubmit,
  });

  const configValues: {
    [key: string]: React.ReactNode;
  } = {
    easyQuestionsPoints: (
      <FormattedMessage
        values={{ count: byComplexity.easy.correctAnswerPoints }}
        {...messages.points}
      />
    ),
    mediumQuestionsPoints: (
      <FormattedMessage
        values={{ count: byComplexity.medium.correctAnswerPoints }}
        {...messages.points}
      />
    ),
    hardQuestionsPoints: (
      <FormattedMessage
        values={{ count: byComplexity.hard.correctAnswerPoints }}
        {...messages.points}
      />
    ),
    secondLeftPoints: (
      <FormattedMessage
        values={{ count: secondLeftPoints }}
        {...messages.points}
      />
    ),
    notUsedBoosterPoints: (
      <FormattedMessage
        values={{ count: notUsedBoosterPoints }}
        {...messages.points}
      />
    ),
    successfulQuizPoints: (
      <FormattedMessage
        values={{ count: successfulQuizPoints }}
        {...messages.points}
      />
    ),
    easyQuestionsRange: getFormattedRange('easy', byComplexity),
    mediumQuestionsRange: getFormattedRange('medium', byComplexity),
    hardQuestionsRange: getFormattedRange('hard', byComplexity),
    extraHardQuestionsRange: getFormattedRange('extra_hard', byComplexity),
  };

  return (
    <Dialog
      classes={{ paper }}
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      PaperProps={{ elevation: 0 }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          px={{ xs: 7, md: 27 }}
          py={{ xs: 7, md: 5 }}
          width={{ xs: 570, md: 1750 }}
          maxWidth="100%"
        >
          <Box mb={10} textAlign="center">
            <DialogTitle>
              <Typography variant="h1" component="p" mr={{ xs: 6, md: 0 }}>
                <FormattedMessage {...messages.title} />
              </Typography>
              <Box position="absolute" top={30} right={25}>
                <IconButton onClick={onClose}>
                  <Box pt={1} width={{ xs: 35, lg: 45 }}>
                    <ExitIcon />
                  </Box>
                </IconButton>
              </Box>
            </DialogTitle>
          </Box>
          <Box mb={{ xs: 8, md: 12 }}>
            <img
              className={image}
              src={!isMobile ? rulesImageDesktop : rulesImageMob}
              alt={intl.formatMessage(messages.title)}
            />
          </Box>
          {rulesText[locale].map((paragraph) => {
            return (
              <Typography
                mb={{ xs: 10, md: 6 }}
                fontSize={{ xs: 25, md: 35 }}
                lineHeight={{ xs: 1.76, md: 1.26 }}
              >
                {replaceValues(paragraph, configValues)}
              </Typography>
            );
          })}
          <form onSubmit={formik.handleSubmit}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box mb={6}>
                <FormControlLabel
                  name="notShowRules"
                  control={<Checkbox checked={formik.values.notShowRules} />}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notShowRules}
                  label={
                    <Typography color="text.secondary" variant="body1">
                      <FormattedMessage {...messages.checkbox} />
                    </Typography>
                  }
                />
              </Box>

              <DialogActions>
                <Box width={{ xs: 300, md: 550 }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    fullWidth
                  >
                    <FormattedMessage {...messages.submitLabel} />
                  </Button>
                </Box>
              </DialogActions>
            </Box>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RulesPopUp;
