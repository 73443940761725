interface ProgressItemSize {
  small: number;
  medium: number;
  large: number;
}

export interface ProgressItemData {
  number: number;
  activeBackground: string;
  size: keyof ProgressItemSize;
}

const progressBarData: ProgressItemData[] = [
  {
    number: 1,
    activeBackground: '#248411',
    size: 'small',
  },
  {
    number: 2,
    activeBackground: '#398B10',
    size: 'small',
  },
  {
    number: 3,
    activeBackground: '#52930D',
    size: 'small',
  },
  {
    number: 4,
    activeBackground: '#63970C',
    size: 'small',
  },
  {
    number: 5,
    activeBackground: '#799E0A',
    size: 'small',
  },
  {
    number: 6,
    activeBackground: '#8CA408',
    size: 'medium',
  },
  {
    number: 7,
    activeBackground: '#9FAA06',
    size: 'medium',
  },
  {
    number: 8,
    activeBackground: '#B6B105',
    size: 'medium',
  },
  {
    number: 9,
    activeBackground: '#CBB703',
    size: 'medium',
  },
  {
    number: 10,
    activeBackground: '#DFBD01',
    size: 'medium',
  },
  {
    number: 11,
    activeBackground: '#E4B700',
    size: 'medium',
  },
  {
    number: 12,
    activeBackground: '#DBA600',
    size: 'medium',
  },
  {
    number: 13,
    activeBackground: '#CF9000',
    size: 'medium',
  },
  {
    number: 14,
    activeBackground: '#C97F00',
    size: 'medium',
  },
  {
    number: 15,
    activeBackground: '#BA6900',
    size: 'medium',
  },
  {
    number: 16,
    activeBackground: '#AE5300',
    size: 'large',
  },
  {
    number: 17,
    activeBackground: '#A44100',
    size: 'large',
  },
  {
    number: 18,
    activeBackground: '#9A2E00',
    size: 'large',
  },
  {
    number: 19,
    activeBackground: '#921A00',
    size: 'large',
  },
  {
    number: 20,
    activeBackground: '#860800',
    size: 'large',
  },
];

export default progressBarData;
