import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Quiz.RulesScreen.title',
    defaultMessage: 'Rules of the Game',
  },
  points: {
    id: 'Quiz.RulesScreen.points',
    defaultMessage: '{count, plural, one {# point} other {# points}}',
  },
  checkbox: {
    id: 'Quiz.RulesScreen.checkbox',
    defaultMessage: 'Don’t show this again',
  },
  submitLabel: {
    id: 'Quiz.RulesScreen.submitLabel',
    defaultMessage: 'Ok',
  },
});
