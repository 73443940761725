import { makeStyles } from '@mui/styles';
import { ITheme } from '../../../common/theme';

export default makeStyles((theme: ITheme) => ({
  paper: {
    height: 'auto',
    borderWidth: 5,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.dark,
    borderRadius: 25,
    [theme.breakpoints.down('md')]: {
      background: theme.palette.common.white,
    },
  },
  image: {
    width: '100%',
    maxWidth: 560,
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
  },
}));
