import { defineMessages } from 'react-intl';

export default defineMessages({
  homeLinkTitle: {
    id: 'Unknown.QuizScreen.homeLinkTitle',
    defaultMessage: 'Return to Home',
  },
  rulesLinkTitle: {
    id: 'Unknown.QuizScreen.rulesLinkTitle',
    defaultMessage: 'Rules of the Game',
  },
  halfAnswers: {
    id: 'Unknown.QuizScreen.halfAnswers',
    defaultMessage: 'Half Answers',
  },
  newQuestion: {
    id: 'Unknown.QuizScreen.newQuestion',
    defaultMessage: 'New Question',
  },
  newTimer: {
    id: 'Unknown.QuizScreen.newTimer',
    defaultMessage: 'New Timer',
  },
  nextQuestion: {
    id: 'Unknown.QuizScreen.nextQuestion',
    defaultMessage: 'Next',
  },
  next: {
    id: 'Unknown.QuizScreen.next',
    defaultMessage: 'Next',
  },
  errorMessage: {
    id: 'Unknown.QuizScreen.errorMessage',
    defaultMessage: 'Unable to take action',
  },
  startButtonLabel: {
    id: 'Unknown.QuizScreen.startButtonLabel',
    defaultMessage: 'Start',
  },
  yes: {
    id: 'Unknown.QuizScreen.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'Unknown.QuizScreen.no',
    defaultMessage: 'No',
  },
  confirmExitMessage: {
    id: 'Unknown.QuizScreen.confirmExitMessage',
    defaultMessage: 'Are you sure you want to exit?',
  },
});
