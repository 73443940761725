const LEGAL_PAGE_IDS = {
  termsAndConditions: 'terms-and-conditions',
  privacyPolicy: 'privacy-policy',
  cookiesPolicy: 'cookies-policy',
};

const FLIPPER_ANIMATION_MS = 500;

const MS_IN_SEC = 1000;

const DEFAULT_LOCALE = 'en';

export { LEGAL_PAGE_IDS, FLIPPER_ANIMATION_MS, DEFAULT_LOCALE, MS_IN_SEC };
