import { defineMessages } from 'react-intl';

export default defineMessages({
  startButtonLabel: {
    id: 'Unknown.HomeScreen.startButtonLabel',
    defaultMessage: 'Enter',
  },
  loadingButtonLabel: {
    id: 'Unknown.SignInScreen.loadingButtonLabel',
    defaultMessage: 'Loading...',
  },
  title: {
    id: 'Unknown.HomeScreen.title',
    defaultMessage: 'Welcome to Tada Games!',
  },
  description: {
    id: 'Unknown.HomeScreen.description',
    defaultMessage: `You know us as PlayTada, we’ve been quiet as of late, but that’s because we’ve been working hard on our new prize gaming platform and we’d like you to try it out for free!

    This week we will be playing for an Amazon voucher worth £10, with a winner chosen at random this Friday at 6.00pm!  The more points you get, the more chance you have of winning, play as many times as you want and share with all your friends.`,
  },
  tip: {
    id: 'Unknown.HomeScreen.tip',
    defaultMessage:
      'Click Start now to be in with a chance of winning, and keep checking back with us as we’ll be releasing new games soon!',
  },
  rulesLinkTitle: {
    id: 'Unknown.HomeScreen.rulesLinkTitle',
    defaultMessage: 'Rules of the Game',
  },
  cookiesLinkTitle: {
    id: 'Unknown.HomeScreen.cookiesLinkTitle',
    defaultMessage: 'Cookies Policy',
  },
  privacyLinkTitle: {
    id: 'Unknown.HomeScreen.privacyLinkTitle',
    defaultMessage: 'Privacy Policy',
  },
  termsLinkTitle: {
    id: 'Unknown.HomeScreen.termsLinkTitle',
    defaultMessage: 'Terms & Conditions',
  },
  homeLinkTitle: {
    id: 'Unknown.HomeScreen.homeLinkTitle',
    defaultMessage: 'Return to Home',
  },
  quizErrorMessage: {
    id: 'Unknown.HomeScreen.quizErrorMessage',
    defaultMessage: 'Unable to start the game reload the page and try again',
  },
});
