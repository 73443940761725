import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Box from '../Box';
import Typography from '../Typography';
import GradientCircle from '../GradientCircle';
import useStyles from './useStyles';
import messages from './messages';

interface PrizePreviewProps {
  prizeName: string;
  imageSrc: string;
}

const PrizePreview: React.FC<PrizePreviewProps> = ({ prizeName, imageSrc }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isSmallDesktop = useMediaQuery(theme.breakpoints.between('md', 'xl'));

  let smallCircleSize;
  let bigCircleSize;

  switch (true) {
    case isMobile:
      smallCircleSize = 100;
      bigCircleSize = 200;
      break;
    case isTablet:
      smallCircleSize = 167;
      bigCircleSize = 355;
      break;
    case isSmallDesktop:
      smallCircleSize = 210;
      bigCircleSize = 440;
      break;
    default:
      smallCircleSize = 250;
      bigCircleSize = 570;
  }

  return (
    <Box position="relative">
      <GradientCircle size={bigCircleSize}>
        <Box
          height={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img className={classes.image} src={imageSrc} alt="bmw" />
        </Box>
      </GradientCircle>
      <Box
        position="absolute"
        top={{ xs: 1, md: 5 }}
        left={{ xs: -58, sm: -80, md: -100, xl: -120 }}
      >
        <GradientCircle size={smallCircleSize}>
          <Box textAlign="center" pt={{ xs: 4, sm: 8, md: 10, xl: 12 }}>
            <Typography
              classes={{ root: classes.text }}
              variant="subtitle2"
              color="text.primary"
            >
              <FormattedMessage {...messages.win} />
            </Typography>
            <Typography
              classes={{ root: classes.text }}
              variant="subtitle2"
              color="text.secondary"
            >
              {prizeName}
            </Typography>
          </Box>
        </GradientCircle>
      </Box>
    </Box>
  );
};

export default PrizePreview;
