import React from 'react';
import { InstagramIcon, FacebookIcon, TwitterIcon } from '../Icons';

const socialLinksData = [
  {
    href: 'https://www.facebook.com/playtada/',
    icon: <FacebookIcon />,
  },
  {
    href: 'https://mobile.twitter.com/playtada/',
    icon: <TwitterIcon />,
  },
  {
    href: 'https://www.instagram.com/playtada/',
    icon: <InstagramIcon />,
  },
];

export default socialLinksData;
