import React from 'react';
import Box from '../Box';
import useStyles from './useStyles';

interface GradientCircleProps {
  size: number;
}

const GradientCircle: React.FC<GradientCircleProps> = ({ size, children }) => {
  const classes = useStyles();
  const borderWidth = 6;
  const innerCircleSize = size - borderWidth * 2;

  return (
    <Box
      className={classes.circleWrapper}
      width={size}
      height={size}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="100%"
    >
      <Box
        width={innerCircleSize}
        height={innerCircleSize}
        bgcolor="common.white"
        borderRadius="100%"
      >
        {children}
      </Box>
    </Box>
  );
};

export default GradientCircle;
