import firebase from 'firebase/app';
import { GameConfig } from '../../../types';

export default async (): Promise<GameConfig | null> => {
  const db = firebase.firestore();
  const now = new Date();
  const configCollectionRef = await db
    .collection('gameConfigs')
    .where('endAt', '>', now)
    .get();

  // Cloud Firestore does not support filtering by two fields, so the following code snippet filters configs by start date.
  // https://firebase.google.com/docs/firestore/query-data/queries#query_limitations

  if (configCollectionRef.size === 0)
    throw new Error('There is no active config');

  const currentConfig = configCollectionRef.docs.reduce((prev, curr) => {
    return prev.data().startAt.toMillis() < now.getTime() ? prev : curr;
  });

  if (currentConfig.exists) {
    const gc = currentConfig.data() as GameConfig;
    gc.id = currentConfig.id;
    return gc;
  }

  throw new Error('There is no active config');
};
