import React, { useState, useEffect, useContext, CSSProperties } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import Big from 'big.js';
import firebase from 'firebase/app';
import { useMediaQuery, useTheme } from '@mui/material';
import { MS_IN_SEC } from '../../../common/constants';
import delayFor from '../../../common/delayFor';
import { QuizPublic } from '../../../../types';
import Box from '../../Unknown/Box';
import ProgressBar from '../../Unknown/ProgressBar';
import Typography from '../../Unknown/Typography';
import Container from '../../Unknown/Container';
import GameFrame from '../../Unknown/GameFrame';
import Booster from '../../Unknown/Booster';
import Grid from '../../Unknown/Grid';
import Header from '../../Unknown/Header';
import CircularProgress from '../../Unknown/CircularProgress';
import Button from '../../Unknown/Button';
import Snackbar from '../../Unknown/Snackbar';
import Flipper from '../../Unknown/Flipper';
import RouterPrompt from '../../Unknown/RouterPrompt';
import ConfirmModal from '../../Unknown/ConfirmModal';
import { ConfigContext } from '../ConfigContext';
import CongratulationPopUp from '../CongratulationPopUp';
import GameBoard from '../GameBoard';
import messages from './messages';
import useFunctions from './useFunctions';
import useStyles from './useStyles';

const QuizScreen: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [currentAnswerId, setCurrentAnswerId] = useState('');
  const [correctAnswerId, setCorrectAnswerId] = useState('');
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [isBoardFlipped, setIsBoardFlipped] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isAnswerIncorrect, setIsAnswerIncorrect] = useState(false);
  const [countdownValue, setCountdownValue] = useState(3);
  const [quizActive, setQuizActive] = useState(false);
  const [quizStarted, setQuizStarted] = useState(false);
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [disableRules, setDisableRules] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [disabledAnswerIds, setDisabledAnswerIds] = useState<string[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [boosterLoaders, setBoosterLoaders] = useState<{
    [key: string]: boolean;
  }>({
    half_answers_booster: false,
    change_question_booster: false,
    reset_timer_booster: false,
  });
  const [timeLeft, setTimeLeft] = useState<number>();
  const errorDelay = 5000;
  const halfAnswersDelay = 2000;
  const answerDelay = 500;
  const {
    answerQuestion,
    halfAnswers,
    nextQuestion,
    changeQuestion,
    resetTimer,
    updateExpiredQuiz,
  } = useFunctions();
  const classes = useStyles();
  const firestore = useFirestore();
  const history = useHistory();
  const intl = useIntl();
  const { id: quizId } = useParams<{ id: string }>();
  const {
    config: {
      prize: { name: prizeImageAlt, imageUrl: prizeImageSrc },
      milestoneIndexes,
      questionDurationSeconds,
      popupDelaySeconds,
    },
  } = useContext(ConfigContext);

  const currentQuizRef = firestore.collection('quizzesPublic').doc(quizId);
  const isAnswerCorrect =
    currentAnswerId && correctAnswerId === currentAnswerId;

  const { data: currentQuiz, status } =
    useFirestoreDocData<QuizPublic>(currentQuizRef);

  const handleSnackbarClose = () => {
    setErrorMessage(null);
  };

  const onTimerExpired = async () => {
    try {
      await updateExpiredQuiz({ quizId });
    } catch (error) {
      setErrorMessage(intl.formatMessage(messages.errorMessage));
    }
  };

  const handleHistoryBack = () => {
    history.goBack();
  };

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft !== undefined && isTimerActive) {
        if (timeLeft === 0) {
          onTimerExpired();
          return;
        }
        setTimeLeft(timeLeft - 1);
      }
    }, MS_IN_SEC);

    return () => clearInterval(timer);
  });

  useEffect(() => {
    const countdown = setTimeout(() => {
      if (quizStarted) {
        if (countdownValue > 1) {
          setCountdownValue((value) => value - 1);
        } else {
          setQuizActive(true);
          setTimeLeft(questionDurationSeconds);
        }
      }
    }, MS_IN_SEC);

    return () => clearTimeout(countdown);
  }, [countdownValue, questionDurationSeconds, quizStarted]);

  useEffect(() => {
    const flipBoard = async () => {
      setIsBoardFlipped((flipped) => !flipped);
    };

    flipBoard();
  }, [currentQuiz?.currentQuestion?.id, questionDurationSeconds]);

  useEffect(() => {
    if (quizStarted) setTimeLeft(questionDurationSeconds);
  }, [currentQuiz?.currentQuestionIndex, questionDurationSeconds, quizStarted]);

  useEffect(() => {
    if (
      currentQuiz?.status === 'failed' ||
      currentQuiz?.status === 'succeeded'
    ) {
      const delayMs = new Big(popupDelaySeconds).mul(MS_IN_SEC).toNumber();
      const openPopup = async () => {
        if (currentQuiz?.status === 'failed') await delayFor(delayMs);
        setIsPopupOpen(true);
      };
      openPopup();
    }
  }, [currentQuiz?.status, popupDelaySeconds]);

  if (status !== 'success') return null;

  const {
    amountLeftByBoosterType,
    currentQuestion,
    currentQuestionIndex,
    totalQuestionsCount,
    totalPoints,
    status: quizStatus,
  } = currentQuiz;
  const currentIndex =
    currentQuestionIndex >= 0 ? currentQuestionIndex + 1 : totalQuestionsCount;
  const popUpStatus = quizStatus !== 'in_progress' ? quizStatus : undefined;

  const onNextQuestionClick = async () => {
    try {
      setCurrentAnswerId('');
      setCorrectAnswerId('');
      setDisabledAnswerIds([]);
      setLoadingQuestion(true);
      setDisableRules(true);
      await nextQuestion({
        quizId,
      });
      setIsTimerActive(true);
    } catch (error) {
      setErrorMessage(intl.formatMessage(messages.errorMessage));
    }
  };

  const onAnswerOptionClick = async (answerId: string) => {
    if (!isTimerActive) return;
    try {
      setLoadingQuestion(false);
      setIsTimerActive(false);
      setDisableRules(false);
      setCurrentAnswerId(answerId);
      const answerResult = (
        await answerQuestion({
          quizId,
          questionId: currentQuestion?.id,
          answerId,
          secondsLeft: timeLeft,
        })
      ).data;
      if (!answerResult.isAnswerCorrect) {
        setIsAnswerIncorrect(true);
      }
      await delayFor(answerDelay);
      setCorrectAnswerId(answerResult.correctAnswerOptionId);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
      setCurrentAnswerId('');
    }
  };

  const onStartClick = async () => {
    try {
      setLoadingQuestion(true);
      setQuizStarted(true);
      setDisableRules(true);
      await nextQuestion({
        quizId,
      });
    } catch (error) {
      setQuizStarted(false);
      setLoadingQuestion(false);
      setErrorMessage(intl.formatMessage(messages.errorMessage));
    }
  };

  const onBoosterClick = async (
    cloudFunction: firebase.functions.HttpsCallable,
    boosterType: string,
  ) => {
    setIsTimerActive(false);
    if (boosterType === 'new_timer_booster') {
      setTimeLeft(questionDurationSeconds);
    }
    setBoosterLoaders({ ...boosterLoaders, [boosterType]: true });
    try {
      if (boosterType === 'half_answers_booster') {
        const isUsed = !!disabledAnswerIds.length;
        const highlightedAnswers = (await cloudFunction({ quizId, isUsed }))
          .data;
        setDisabledAnswerIds(highlightedAnswers);
      } else {
        await cloudFunction({ quizId });
      }
    } catch (error) {
      setErrorMessage(intl.formatMessage(messages.errorMessage));
    } finally {
      setIsTimerActive(true);
      await delayFor(halfAnswersDelay);
      setBoosterLoaders({ ...boosterLoaders, [boosterType]: false });
    }
  };

  const onConfirmPrompt = () => {
    onTimerExpired();
    return true;
  };

  const onCancelPrompt = () => false;

  const boosters = [
    {
      type: 'half_answers_booster',
      label: messages.halfAnswers,
      cloudFunction: halfAnswers,
    },
    {
      type: 'new_question_booster',
      label: messages.newQuestion,
      cloudFunction: changeQuestion,
    },
    {
      type: 'new_timer_booster',
      label: messages.newTimer,
      cloudFunction: resetTimer,
    },
  ];

  const nextQuestionButton = (
    <Button
      size="small"
      variant="outlined"
      color="secondary"
      // color="primary"
      disabled={!quizActive || !isAnswerCorrect}
      onClick={onNextQuestionClick}
    >
      Next
      {/* <FormattedMessage {...messages.next} /> */}
    </Button>
  );

  const gameBoard = currentQuestion && (
    <GameBoard
      question={currentQuestion}
      currentAnswerId={currentAnswerId}
      correctAnswerId={correctAnswerId}
      isAnswerIncorrect={isAnswerIncorrect}
      onAnswerOptionClick={onAnswerOptionClick}
      isTimerActive={isTimerActive}
      timeLeft={timeLeft}
      disabledAnswerIds={disabledAnswerIds}
    />
  );

  const booterStyle: CSSProperties = {
    position: 'absolute',
    left: '0',
    right: '0',
    margin: 'auto',
  };
  const navStyle: CSSProperties = {
    position: 'relative',
  };

  if (isMobile) {
    booterStyle.position = 'relative';
    delete booterStyle.left;
    delete booterStyle.right;
    delete booterStyle.margin;
    navStyle.display = 'flex';
    navStyle.alignItems = 'center';
  }
  return (
    <Box
      key={currentQuestion?.text}
      minHeight="100vh"
      className={classes.screen}
    >
      <Container maxWidth="xl">
        <Box height={1}>
          <Header disableRules={disableRules} totalPoints={totalPoints} />
          <Box
            id="progress-bar"
            mt={{ xs: 2, lg: 2 }}
            maxWidth={1505}
            mx="auto"
          >
            <ProgressBar
              activeItemNumber={
                quizActive && currentQuestion ? currentIndex : 0
              }
              milestoneIndexes={milestoneIndexes}
            />
            {
              <>
                <Box
                  id="navigation"
                  py={4}
                  alignItems={{
                    xs: 'start',
                    lg: 'start',
                  }}
                  style={navStyle}
                >
                  <Box
                    id="booster"
                    // xs={12}
                    // sm={6}
                    // order={{ xs: 1, sm: 0 }}
                    // mt={{ xs: 4, sm: 0 }}
                    width="50%"
                    display="flex"
                    justifyContent="space-evenly"
                    style={booterStyle}
                  >
                    {boosters.map(({ type, label, cloudFunction }) => {
                      const onClick = () => onBoosterClick(cloudFunction, type);
                      const isDisabled =
                        !currentQuestion ||
                        !quizActive ||
                        !isTimerActive ||
                        !amountLeftByBoosterType[type] ||
                        boosterLoaders[type];

                      return (
                        <Box
                          key={type}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          ml={{ xs: 4 }}
                        >
                          <Booster
                            iconType={type}
                            amount={amountLeftByBoosterType[type]}
                            disabled={isDisabled}
                            onClick={onClick}
                          />
                          <Typography
                            mt={1}
                            variant="h5"
                            display={{ xs: 'none', lg: 'block' }}
                          >
                            <FormattedMessage {...label} />
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                  <Grid
                    id="next-question"
                    item
                    pr={{ sm: 1, md: 0 }}
                    // xs={12}
                    // sm={6}
                    display="flex"
                    flex="1"
                    justifyContent={{
                      xs: 'flex-end',
                      sm: 'flex-end',
                      md: 'flex-end',
                    }}
                  >
                    {/* <ScoreBoard score={totalPoints} /> */}
                    <Box mt={1} display="flex">
                      {nextQuestionButton}
                    </Box>
                  </Grid>
                </Box>
              </>
            }
            <Box
              id="game-board-area"
              width={1}
              height={1}
              my={{ xs: 3, md: 8, lg: 8 }}
            >
              {quizActive && currentQuestion && (
                <Flipper
                  frontContent={
                    <GameFrame>
                      {isBoardFlipped && loadingQuestion ? (
                        <CircularProgress />
                      ) : (
                        gameBoard
                      )}
                    </GameFrame>
                  }
                  backContent={
                    <GameFrame>
                      {!isBoardFlipped && loadingQuestion ? (
                        <CircularProgress />
                      ) : (
                        gameBoard
                      )}
                    </GameFrame>
                  }
                  isFlipped={isBoardFlipped}
                />
              )}
              {quizActive && !currentQuestion && (
                <GameFrame>
                  <CircularProgress />
                </GameFrame>
              )}
              {!quizStarted && (
                <GameFrame>
                  <Box
                    id="game-board-quiz-not-started"
                    mx="auto"
                    width={{ xs: 520, md: 615 }}
                    maxWidth={1}
                    minHeight={{ xs: 400, md: 400 }}
                    display="flex"
                    alignItems="center"
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="large"
                      onClick={onStartClick}
                      fullWidth
                    >
                      <FormattedMessage {...messages.startButtonLabel} />
                    </Button>
                  </Box>
                </GameFrame>
              )}
              {!quizActive && quizStarted && (
                <GameFrame>
                  <Box
                    id="game-board-quiz-active-started"
                    mx="auto"
                    width={{ xs: 520, md: 615 }}
                    maxWidth={1}
                    minHeight={{ xs: 400, md: 400 }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="body1"
                      fontSize={{ xs: 150, sm: 150, md: 150, lg: 150 }}
                      fontWeight={900}
                      lineHeight={1.1}
                    >
                      {countdownValue}
                    </Typography>
                  </Box>
                </GameFrame>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
      {errorMessage && (
        <Snackbar
          open={Boolean(errorMessage)}
          autoHideDuration={errorDelay}
          onClose={handleSnackbarClose}
          message={errorMessage}
        />
      )}
      <CongratulationPopUp
        open={isPopupOpen}
        entries={totalPoints}
        prizeImageSrc={prizeImageSrc}
        imageAlt={prizeImageAlt}
        quizResult={popUpStatus}
      />
      <ConfirmModal
        open={confirmModalOpen}
        onClose={openConfirmModal}
        message={<FormattedMessage {...messages.confirmExitMessage} />}
        confirmButtonLabel={<FormattedMessage {...messages.yes} />}
        cancelButtonLabel={<FormattedMessage {...messages.no} />}
        onConfirm={handleHistoryBack}
        onCancel={closeConfirmModal}
      />
      <RouterPrompt
        when={quizStatus === 'in_progress'}
        onConfirm={onConfirmPrompt}
        onCancel={onCancelPrompt}
      />
    </Box>
  );
};

export default QuizScreen;
