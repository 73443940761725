import React, { useEffect, useState, useContext } from 'react';
import Big from 'big.js';
import { ReactComponent as TimerIconOrange } from './timer-icon-orange.svg';
import { ReactComponent as TimerIconRed } from './timer-icon-red.svg';
import Box from '../Box';
import Typography from '../Typography';
import CircularProgress from '../CircularProgress';
import useStyles from './useStyles';
import { ConfigContext } from '../../Quiz/ConfigContext';

interface TimerProps {
  isActive: boolean;
  timeLeft: number;
}

const Timer: React.FC<TimerProps> = ({ timeLeft, isActive }) => {
  const {
    config: { questionDurationSeconds },
  } = useContext(ConfigContext);
  const warningSecondsLeft = 10;
  const [timerActive, setTimerActive] = useState(true);
  const [isLastSecond, setIsLastSecond] = useState(false);
  const bigTimeLeft = new Big(timeLeft);
  const isWarning = bigTimeLeft.lte(warningSecondsLeft);
  const formattedTimeLeft = bigTimeLeft.gt(9) ? timeLeft : `0${timeLeft}`;
  const classes = useStyles({ isWarning, isLastSecond });
  const timeProgress = new Big(questionDurationSeconds)
    .minus(timeLeft)
    .div(questionDurationSeconds)
    .mul(100)
    .toNumber();

  useEffect(() => {
    if (timeLeft === 1) {
      setIsLastSecond(true);
    }
    if (timeLeft < 1 && isActive) {
      setTimerActive(false);
    }
  }, [timeLeft, isActive]);

  return (
    <Box
      position="relative"
      // width={{ xs: 125, lg: 186 }}
      // height={{ xs: 125, lg: 186 }}
      width={{ xs: 100, lg: 150 }}
      height={{ xs: 100, lg: 150 }}
      className={
        timerActive ? classes.timer : `${classes.timer} ${classes.removeTimer}`
      }
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        bottom={0}
        right={0}
        borderRadius="100%"
        boxShadow={{
          xs: '0px 0px 15px 10px #000000 inset',
          md: '0px 0px 20px 15px #000000 inset',
        }}
      />
      <Box
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        zIndex={-2}
        bgcolor="primary.main"
        borderRadius="100%"
      >
        <CircularProgress
          classes={{ root: classes.progress }}
          size="100%"
          thickness={8.5}
          variant="determinate"
          value={100}
        />
      </Box>
      <Box
        position="absolute"
        top={0}
        left={0}
        bottom={0}
        right={0}
        zIndex={-1}
        borderRadius="100%"
      >
        <CircularProgress
          classes={{ circle: classes.progressMask }}
          size="100%"
          thickness={8.6}
          color="primary"
          variant="determinate"
          value={timeProgress}
        />
      </Box>
      <Box
        position="absolute"
        top={0}
        left={0}
        bottom={0}
        right={0}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          className={classes.middleShadowMask}
          width={{ xs: 71, lg: 102 }}
          height={{ xs: 71, lg: 102 }}
          bgcolor="primary.dark"
          borderRadius="100%"
        />
      </Box>
      <Typography
        key={formattedTimeLeft}
        classes={{ root: isWarning ? classes.textAnimation : undefined }}
        color={isWarning ? 'common.red' : 'common.white'}
        fontSize={{ xs: 50, lg: 75 }}
        lineHeight={1}
        fontWeight={700}
        zIndex={3}
      >
        {formattedTimeLeft}
      </Typography>
      <Box width="106%" height="112%" position="absolute" left={-1} bottom={-1}>
        {isWarning ? <TimerIconRed /> : <TimerIconOrange />}
      </Box>
    </Box>
  );
};

export default Timer;
