/* eslint-disable react/require-default-props */

import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '../../Unknown/Box';
import Button from '../../Unknown/Button';
import Typography from '../../Unknown/Typography';
import Grid from '../../Unknown/Grid';
import Timer from '../../Unknown/Timer';
import { QuestionPublic } from '../../../../types';
import getButtonColor from './getButtonColor';

export interface GameBoardProps {
  question: QuestionPublic;
  currentAnswerId: string;
  correctAnswerId: string;
  isAnswerIncorrect: boolean;
  onAnswerOptionClick: (answerId: string) => void;
  isTimerActive: boolean;
  timeLeft?: number;
  disabledAnswerIds: string[];
}

const GameBoard: React.FC<GameBoardProps> = ({
  question,
  currentAnswerId,
  correctAnswerId,
  isAnswerIncorrect,
  onAnswerOptionClick,
  isTimerActive,
  timeLeft,
  disabledAnswerIds,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { text, answerOptions } = question;

  return (
    <Box
      id="game-board"
      alignSelf="stretch"
      py={{ xs: 5, md: 4 }}
      px={{ xs: 4, md: 5 }}
    >
      {!isDesktop && (
        <Box mt={{ xs: 0, md: 5 }} display="flex" justifyContent="center">
          {timeLeft !== undefined && (
            <Timer timeLeft={timeLeft} isActive={isTimerActive} />
          )}
        </Box>
      )}
      <Typography textAlign="center" mt={{ xs: 6, md: 3, lg: 6 }} variant="h2">
        {text}
      </Typography>
      <Box mt={{ xs: 5, md: 4, lg: 17 }} display="flex" alignItems="center">
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {answerOptions.map((answer) => {
            const isActive = answer.id === currentAnswerId;
            const isCorrect = answer.id === correctAnswerId;
            const buttonColor = getButtonColor({
              isActive,
              isCorrect,
              isAnswerIncorrect,
            });
            const isDisabled = disabledAnswerIds?.includes(answer.id);

            return (
              <Grid key={answer.id} item xs={12} md={6}>
                <Button
                  variant="contained"
                  size="small"
                  color={buttonColor}
                  fullWidth
                  onClick={() => onAnswerOptionClick(answer.id)}
                  disabled={isDisabled}
                >
                  {answer.text}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        {isDesktop && (
          <Box ml={4} flexShrink={1} id="grid-wrapper">
            {timeLeft !== undefined && (
              <Timer timeLeft={timeLeft} isActive={isTimerActive} />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GameBoard;
