import { Box } from '@mui/material';
import React from 'react';
import { useFirestore, useFirestoreDocData, useUser } from 'reactfire';
import { User } from '../../../../types';

interface AuthenticatedLayoutProps {
  children: React.ReactElement;
}

const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({
  children,
}: AuthenticatedLayoutProps) => {
  // we expect that it's always set when in this layout
  const { data: authUser } = useUser();
  const userRef = useFirestore().collection('users').doc(authUser.uid);

  const { data: user, hasEmitted } = useFirestoreDocData<User>(userRef, {
    idField: 'id',
  });
  if (!hasEmitted) {
    return null;
  }

  if (!user) {
    return null;
  }

  return <Box>{children}</Box>;
};

export default AuthenticatedLayout;
