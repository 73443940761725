import React from 'react';
import { ReactComponent as GoldFiftyFiftyIcon } from './gold-fifty-fifty.svg';
import { ReactComponent as SilverFiftyFiftyIcon } from './silver-fifty-fifty.svg';
import { ReactComponent as GoldQuestionMarkIcon } from './gold-question-mark.svg';
import { ReactComponent as SilverQuestionMarkIcon } from './silver-question-mark.svg';
import { ReactComponent as GoldTimerIcon } from './gold-timer.svg';
import { ReactComponent as SilverTimerIcon } from './silver-timer.svg';
// import Box from '../Box';

interface Icon {
  active: React.ReactElement;
  disabled: React.ReactElement;
}

export interface BoosterIcons {
  [type: string]: Icon;
}

const boosterIcons: BoosterIcons = {
  half_answers_booster: {
    active: (
      // <Box
      //   maxWidth={{ xs: 50, sm: 72, md: 90, xl: 110 }}
      //   maxHeight={{ xs: 41, sm: 31, md: 37, xl: 44 }}
      // >
      <GoldFiftyFiftyIcon />
      // </Box>
    ),
    disabled: (
      // <Box
      //   maxWidth={{ xs: 50, sm: 72, md: 90, xl: 110 }}
      //   maxHeight={{ xs: 41, sm: 31, md: 37, xl: 44 }}
      // >
      <SilverFiftyFiftyIcon />
      // </Box>
    ),
  },
  new_question_booster: {
    active: (
      // <Box
      //   maxWidth={{ xs: 40, sm: 52, md: 72, xl: 92 }}
      //   maxHeight={{ xs: 46, sm: 60, md: 82, xl: 105 }}
      // >
      <GoldQuestionMarkIcon />
      // </Box>
    ),
    disabled: (
      // <Box
      //   maxWidth={{ xs: 40, sm: 52, md: 72, xl: 92 }}
      //   maxHeight={{ xs: 46, sm: 60, md: 82, xl: 105 }}
      // >
      <SilverQuestionMarkIcon />
      // </Box>
    ),
  },
  new_timer_booster: {
    active: (
      // <Box
      //   maxWidth={{ xs: 39, sm: 47, md: 64, xl: 81 }}
      //   maxHeight={{ xs: 48, sm: 57, md: 77, xl: 97 }}
      // >
      <GoldTimerIcon />
      // </Box>
    ),
    disabled: (
      // <Box
      //   maxWidth={{ xs: 39, sm: 47, md: 64, xl: 81 }}
      //   maxHeight={{ xs: 48, sm: 57, md: 77, xl: 97 }}
      // >
      <SilverTimerIcon />
      // </Box>
    ),
  },
};

export default boosterIcons;
