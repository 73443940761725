import messages from './messages';

export default [
  {
    title: messages.cookiesLinkTitle,
    link: '/cookies-policy',
  },
  {
    title: messages.privacyLinkTitle,
    link: '/privacy-policy',
  },
  {
    title: messages.termsLinkTitle,
    link: '/terms-and-conditions',
  },
];
