import { defineMessages } from 'react-intl';

export default defineMessages({
  homeButtonLabel: {
    id: 'Unknown.MobileMenu.homeButtonLabel',
    defaultMessage: 'Home',
  },
  rulesButtonLabel: {
    id: 'Unknown.MobileMenu.rulesButtonLabel',
    defaultMessage: 'Rules of the Game',
  },
  exitButtonLabel: {
    id: 'Unknown.MobileMenu.exitButtonLabel',
    defaultMessage: 'Exit',
  },
});
