import usePersistedState from 'use-persisted-state';

const useDontShowRules = (): {
  dontShowRules: boolean;
  setDontShowRules: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const useDontShowRulesState = usePersistedState('dontShowRules');
  const [dontShowRules, setDontShowRules] = useDontShowRulesState(false);

  return { dontShowRules, setDontShowRules };
};

export default useDontShowRules;
