import { makeStyles } from '@mui/styles';

export default makeStyles({
  topMask: ({ disabled }: { disabled: boolean }) => {
    const gradientColor = disabled ? '255,255,255' : '0,190,255';

    return {
      filter: 'blur(6px)',
      background: `linear-gradient(360deg, rgba(${gradientColor},1) 0%, rgba(${gradientColor},0) 55%, rgba(${gradientColor},0) 76%, rgba(${gradientColor},1) 100%)`,
    };
  },
  darkMask: {
    filter: 'blur(10px)',
  },
});
