import React from 'react';
import { Radio as RadioBase, RadioProps } from '@mui/material';
import { ReactComponent as CheckedIcon } from './checked-icon.svg';
import { ReactComponent as UncheckedIcon } from './unchecked-icon.svg';
import Box from '../Box';

const Radio: React.FC<RadioProps> = (props) => {
  return (
    <RadioBase
      {...props}
      checkedIcon={
        <Box width={{ xs: 50, md: 60 }} height={{ xs: 50, md: 60 }}>
          <CheckedIcon />
        </Box>
      }
      icon={
        <Box width={{ xs: 50, md: 60 }} height={{ xs: 50, md: 60 }}>
          <UncheckedIcon />
        </Box>
      }
    />
  );
};

export default Radio;
