export default ({
  isActive,
  isCorrect,
  isAnswerIncorrect,
}: {
  [key: string]: boolean;
}): 'success' | 'error' | 'primary' | 'info' => {
  let buttonColor: 'success' | 'error' | 'primary' | 'info' = 'primary';
  if (isActive) buttonColor = 'info';
  if (isActive && isAnswerIncorrect) buttonColor = 'error';
  if (isCorrect) buttonColor = 'success';

  return buttonColor;
};
