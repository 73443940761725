import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';
import IconButton from '../IconButton';
import Box from '../Box';
import Typography from '../Typography';
import AppBar from '../AppBar';
import Toolbar from '../Toolbar';
import MobileMenu from '../MobileMenu';
import messages from './messages';
import { ExitIcon } from '../Icons';
import RulesPopUp from '../../Quiz/RulesPopUp';
import ScoreBoard from '../ScoreBoard';

interface HeaderProps {
  disableRules: boolean;
  totalPoints: number;
}

const Header: React.FC<HeaderProps> = ({ disableRules, totalPoints }) => {
  const [rulesPopUpOpen, setRulesPopUpOpen] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'));

  const handleExit = async () => {
    history.push('/');
  };

  const onOpenRules = () => {
    setRulesPopUpOpen(true);
  };

  const onCloseRules = () => {
    setRulesPopUpOpen(false);
  };

  // const {
  //   config: { prize },
  // } = useContext(ConfigContext);

  function renderDesktop() {
    const heightInternal = isExtraLarge ? '150px' : '100px';
    return (
      <AppBar position="static">
        <Toolbar disableGutters>
          <div
            style={{
              position: 'relative',
              width: '100%',
              display: 'flex',
              justifyContent: 'end',
              height: heightInternal,
            }}
          >
            <ScoreBoard
              style={{
                position: 'absolute',
                left: '0',
                right: '0',
                margin: 'auto',
              }}
              score={totalPoints}
            />

            {/* <Box ml="auto">{nextQuestionButton}</Box> */}
            <Box ml={{ md: 5, lg: 11 }}>
              <IconButton
                onClick={onOpenRules}
                color="inherit"
                disabled={disableRules}
              >
                <Typography fontSize={{ xs: 40, md: 50 }} fontWeight={900}>
                  ?
                </Typography>
              </IconButton>
              <RulesPopUp open={rulesPopUpOpen} onClose={onCloseRules} />
            </Box>
            <Box ml={{ md: 5, lg: 11 }}>
              <IconButton onClick={handleExit} color="inherit">
                <Box pt={1} width={{ xs: 35, lg: 45 }}>
                  <ExitIcon />
                </Box>
              </IconButton>
            </Box>
          </div>
          ;
        </Toolbar>
      </AppBar>
    );
  }
  function renderMobile() {
    return (
      <AppBar position="static">
        <Toolbar disableGutters>
          <div
            style={{
              position: 'relative',
              width: '100%',
              display: 'flex',
              justifyContent: 'end',
              height: '55px',
            }}
          >
            <ScoreBoard
              style={{
                position: 'absolute',
                left: '0',
                right: '0',
                margin: 'auto',
              }}
              score={totalPoints}
            />
            <MobileMenu
              onHomeClick={handleExit}
              onExitClick={handleExit}
              homeButtonLabel={intl.formatMessage(messages.homeButtonLabel)}
              disableRules={disableRules}
            />
          </div>
        </Toolbar>
      </AppBar>
    );
  }

  if (isMobile) {
    return renderMobile();
  }

  return renderDesktop();
};

export default Header;
