import React from 'react';
import Box from '../Box';
import Typography from '../Typography';
import useStyles from './useStyles';

interface ScoreItemProps {
  // eslint-disable-next-line react/require-default-props
  digit?: string;
}

const ScoreItem: React.FC<ScoreItemProps> = ({ digit }) => {
  const classes = useStyles();

  return (
    <Box
      // key needed to create an animation
      key={digit}
      pt={{ xs: 1, md: 1.5 }}
      width={{ xs: 41, md: 72, xl: 103 }}
      height={{ xs: 63, md: 106, xl: 150 }}
      bgcolor="grey.700"
      borderRadius="10px"
      boxShadow="0px 4px 4px 0px #000000"
    >
      <Box
        className={classes.innerWrapper}
        position="relative"
        height={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderRadius="10px"
      >
        <Box
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {digit && (
            <Typography
              classes={{ root: classes.digit }}
              fontSize={{ xs: 42, md: 71, xl: 100 }}
              color="common.white"
            >
              {digit}
            </Typography>
          )}
        </Box>
        <Box
          className={classes.hinge}
          width={{ xs: 7, md: 11, xl: 16 }}
          height={{ xs: 15, md: 25, xl: 36 }}
          boxShadow={2}
        />
        <Box
          className={classes.axis}
          boxShadow={1}
          zIndex={1}
          flexGrow={2}
          height={2}
        />
        <Box
          className={classes.hinge}
          width={{ xs: 7, md: 11, xl: 16 }}
          height={{ xs: 15, md: 25, xl: 36 }}
          boxShadow={2}
        />
      </Box>
    </Box>
  );
};

export default ScoreItem;
