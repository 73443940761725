import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  image: {
    width: 177,
    [theme.breakpoints.up('sm')]: {
      width: 277,
    },
    [theme.breakpoints.up('md')]: {
      width: 360,
    },
    [theme.breakpoints.up('xl')]: {
      width: 483,
    },
  },
  text: {
    textTransform: 'uppercase',
    fontFamily: 'Impact',
  },
}));
