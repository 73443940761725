import React from 'react';
import Box from '../Box';
import Typography from '../Typography';
import useStyles from './useStyles';
import { RocketIcon } from '../Icons';
import { ProgressItemData } from '../ProgressBar/progressBarItems';

export interface ProgressItemSize {
  small: number;
  medium: number;
  large: number;
}

interface ProgressItemProps extends ProgressItemData {
  number: number;
  active: boolean;
  currentActive: boolean;
  activeBackground: string;
  size: keyof ProgressItemSize;
  divider: boolean;
  // eslint-disable-next-line react/require-default-props
  isMilestone?: boolean;
}

const Progressitem: React.FC<ProgressItemProps> = ({
  number,
  active,
  currentActive,
  activeBackground,
  isMilestone = false,
  divider,
  size,
}) => {
  const classes = useStyles({ active: currentActive });
  const heights = {
    // small: 0.63,
    // medium: 0.82,
    // large: 1,
    small: 0.5,
    medium: 0.63,
    large: 0.82,
  };

  const backgroundColor = active ? activeBackground : 'grey.600';
  const rocketClassName = currentActive ? classes.animatedRocket : '';

  return (
    <Box
      height={heights[size]}
      width={divider ? 1 : 0.75}
      display="flex"
      flexGrow={1}
      position="relative"
    >
      <Box
        className={classes.wrapper}
        width={divider ? 0.75 : 1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={backgroundColor}
      >
        <Box zIndex={1}>
          {isMilestone ? (
            <Box maxWidth={50} className={rocketClassName}>
              <RocketIcon />
            </Box>
          ) : (
            <Typography
              classes={{ root: classes.number }}
              color="text.secondary"
              fontFamily="Impact"
              fontSize={{ xs: 12, sm: 20, md: 30 }}
              fontWeight={700}
            >
              {number}
            </Typography>
          )}
        </Box>
      </Box>
      {divider && <Box width={0.26} bgcolor="common.black" />}
      <Box
        position="absolute"
        top={0}
        right={0}
        left={0}
        bottom={0}
        className={classes.mask}
      />
    </Box>
  );
};

export default Progressitem;
