import React from 'react';
import Box from '../Box';
import CircularProgress from '../CircularProgress';

const Loader: React.FC = () => {
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default Loader;
