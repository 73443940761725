import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmMessage: {
    id: 'Unknown.Header.confirmMessage',
    defaultMessage: 'Are you sure you want to exit?',
  },
  yes: {
    id: 'Unknown.Header.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'Unknown.Header.no',
    defaultMessage: 'No',
  },
  nextQuestion: {
    id: 'Unknown.Header.nextQuestion',
    defaultMessage: 'Next',
  },
  rulesLinkTitle: {
    id: 'Unknown.Header.rulesLinkTitle',
    defaultMessage: 'Rules of the Game',
  },
  cookiesLinkTitle: {
    id: 'Unknown.Header.cookiesLinkTitle',
    defaultMessage: 'Coockies Policy',
  },
  privacyLinkTitle: {
    id: 'Unknown.Header.privacyLinkTitle',
    defaultMessage: 'Privacy Policy',
  },
  termsLinkTitle: {
    id: 'Unknown.Header.termsLinkTitle',
    defaultMessage: 'Terms & Conditions',
  },
  homeButtonLabel: {
    id: 'Unknown.Header.homeButtonLabel',
    defaultMessage: 'Return to Home',
  },
});
