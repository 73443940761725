import React, { useContext, useState } from 'react';
import { useUser } from 'reactfire';
import { useMediaQuery, useTheme } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import messages from './messages';
import TextField from '../../Unknown/TextField';
import RadioGroup from '../../Unknown/RadioGroup';
import Dialog, { DialogProps } from '../../Unknown/Dialog';
import DialogTitle from '../../Unknown/DialogTitle';
import DialogActions from '../../Unknown/DialogActions';
import ConfirmModal from '../../Unknown/ConfirmModal';
import Button from '../../Unknown/Button';
import IconButton from '../../Unknown/IconButton';
import Box from '../../Unknown/Box';
import Grid from '../../Unknown/Grid';
import Typography from '../../Unknown/Typography';
import { ExitIcon } from '../../Unknown/Icons';
import useStyles from './useStyles';
import FormControlLabel from '../../Unknown/FormControlLabel';
import Radio from '../../Unknown/Radio';
import Checkbox from '../../Unknown/Checkbox';
import Link from '../../Unknown/Link';
import { ITheme } from '../../../common/theme';
import validationSchema from './validation';
import { ConfigContext } from '../ConfigContext';
import useFunctions from './useFunctions';
import { UserEntry } from '../../../../types';

interface CongratulationPopUpProps extends DialogProps {
  entries: number;
  prizeImageSrc: string;
  imageAlt: string;
  // eslint-disable-next-line react/require-default-props
  quizResult?: 'succeeded' | 'failed';
}

interface Values {
  email: string;
  subscription: string;
  terms: boolean;
}

const initialValues = {
  email: '',
  subscription: 'agree',
  terms: false,
};

const CongratulationPopUp: React.FC<CongratulationPopUpProps> = ({
  entries,
  prizeImageSrc,
  imageAlt,
  quizResult,
  ...dialogProps
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [playAgainModalOpen, setPlayAgainModalOpen] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const theme = useTheme<ITheme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { paper, uppercase, image } = useStyles();
  const radioButtonArray = [
    { name: 'agree', label: messages.agree },
    { name: 'disagree', label: messages.disagree },
  ];

  const { data: userData } = useUser();
  const userId = userData.uid;
  const { id: quizId } = useParams<{ id: string }>();
  const configContext = useContext(ConfigContext);
  const { endQuiz } = useFunctions();
  const quizEndedAt = new Date().toISOString();

  const openConfirmModal = () => setConfirmModalOpen(true);
  const closeConfirmModal = () => setConfirmModalOpen(false);
  const openPlayAgain = () => setPlayAgainModalOpen(true);
  const closePlayAgainModal = () => setPlayAgainModalOpen(false);
  const onSubmit = async (values: Values) => {
    const {
      id: gameConfigId,
      prize: { name: prizeName },
    } = configContext.config;

    const { email, subscription, terms } = values;

    const data: UserEntry = {
      userId,
      email,
      entries,
      quizId,
      quizEndedAt,
      prizeName,
      subscription,
      terms,
      gameConfigId,
    };
    // alert(JSON.stringify(data));

    await endQuiz(data);

    openPlayAgain();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: false,
    onSubmit,
  });

  const handleGoHomePage = () => {
    history.push({
      pathname: '/',
    });
  };

  const startNewQuiz = () => {
    history.push('/play-again');
  };

  const titleMessage =
    quizResult === 'succeeded' ? messages.title : messages.gameOver;

  return (
    <Dialog
      classes={{ paper }}
      {...dialogProps}
      onClose={openConfirmModal}
      fullScreen={isMobile}
      PaperProps={{ elevation: 0 }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          p={{ xs: 8, md: 10 }}
          width={{ xs: 606, md: 1072 }}
          maxWidth="100%"
        >
          <DialogTitle>
            <Typography
              variant="h1"
              component="p"
              textAlign="center"
              lineHeight={{ xs: 1.2 }}
              classes={{ root: uppercase }}
              mr={{ xs: 6, md: 0 }}
            >
              <FormattedMessage {...titleMessage} />
            </Typography>
            <Box position="absolute" top={30} right={{ xs: 25 }}>
              <IconButton onClick={openConfirmModal}>
                <Box pt={1} width={{ xs: 35, lg: 45 }}>
                  <ExitIcon />
                </Box>
              </IconButton>

              <ConfirmModal
                open={confirmModalOpen}
                onClose={closeConfirmModal}
                message={<FormattedMessage {...messages.exitMessage} />}
                confirmButtonLabel={<FormattedMessage {...messages.yes} />}
                cancelButtonLabel={<FormattedMessage {...messages.no} />}
                onConfirm={handleGoHomePage}
                onCancel={closeConfirmModal}
              />
              <ConfirmModal
                open={playAgainModalOpen}
                onClose={closePlayAgainModal}
                message={<FormattedMessage {...messages.playAgainMessage} />}
                confirmButtonLabel={<FormattedMessage {...messages.yes} />}
                cancelButtonLabel={<FormattedMessage {...messages.no} />}
                onConfirm={startNewQuiz}
                onCancel={closePlayAgainModal}
              />
            </Box>
          </DialogTitle>
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: 'column', md: 'row' }}
            mt={8}
            mx="auto"
          >
            <Grid
              item
              xs={12}
              md={9}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              {quizResult === 'failed' && entries > 0 && (
                <Typography
                  fontSize={{ xs: 40, md: 50 }}
                  className={uppercase}
                  lineHeight={{ xs: 1.2 }}
                  textAlign="center"
                  display="flex"
                >
                  <FormattedMessage {...messages.title} />
                </Typography>
              )}
              <Typography
                fontSize={{ xs: 40, md: 50 }}
                className={uppercase}
                lineHeight={{ xs: 1.2 }}
                textAlign="center"
                display="flex"
              >
                <FormattedMessage
                  values={{
                    entriesAmount: (
                      <Typography
                        fontSize={{ xs: 40, md: 50 }}
                        lineHeight={{ xs: 1.2 }}
                        component="span"
                        mx={2}
                        color="secondary.main"
                      >
                        {entries}
                      </Typography>
                    ),
                  }}
                  {...messages.entriesText}
                />
              </Typography>
            </Grid>
            <Grid item xs={5} md={3} mt={{ xs: 5, md: 0 }}>
              <img className={image} src={prizeImageSrc} alt={imageAlt} />
            </Grid>
          </Grid>
          <Typography mt={{ xs: 2, md: 6 }} fontSize={25} lineHeight={1.2}>
            <FormattedMessage {...messages.text} />
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Box mt={5} px={{ xs: 2, lg: 10 }}>
              <TextField
                placeholder={intl.formatMessage(messages.emailPlaceholder)}
                fullWidth
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                required
              />
            </Box>

            <Typography
              mt={{ xs: 5, md: 7 }}
              variant="body1"
              color="common.maroon"
              lineHeight={1.2}
            >
              <FormattedMessage {...messages.textToContact} />
            </Typography>
            <Box mt={5}>
              <RadioGroup
                name="subscription"
                onChange={formik.handleChange}
                defaultValue="agree"
              >
                {radioButtonArray.map(({ name, label }) => (
                  <FormControlLabel
                    key={name}
                    value={name}
                    control={<Radio />}
                    label={
                      <Typography color="text.secondary" variant="body1">
                        <FormattedMessage {...label} />
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
              <FormControlLabel
                name="terms"
                control={<Checkbox />}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.terms}
                label={
                  <Typography color="text.secondary" variant="body1">
                    <FormattedMessage {...messages.checkbox} />
                    <Link
                      href="/terms-and-conditions"
                      color="text.linkText"
                      target="_blank"
                    >
                      <FormattedMessage {...messages.terms} />
                    </Link>
                  </Typography>
                }
              />
              {formik.errors.terms && formik.touched.terms && (
                <Typography color="common.red" fontSize={20} ml={6}>
                  <FormattedMessage {...messages.termsIsReq} />
                </Typography>
              )}
            </Box>

            <DialogActions>
              <Box mt={3} width={415}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  size="large"
                  fullWidth
                >
                  <FormattedMessage {...messages.submitLabel} />
                </Button>

                <ConfirmModal
                  open={playAgainModalOpen}
                  onClose={openPlayAgain}
                  message={<FormattedMessage {...messages.playAgainMessage} />}
                  confirmButtonLabel={<FormattedMessage {...messages.yes} />}
                  cancelButtonLabel={<FormattedMessage {...messages.no} />}
                  onConfirm={startNewQuiz}
                  onCancel={handleGoHomePage}
                />
              </Box>
            </DialogActions>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CongratulationPopUp;
