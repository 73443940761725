import { useFirebaseApp } from 'reactfire';
import firebase from 'firebase/app';

type FunctionName =
  | 'answerQuestion'
  | 'nextQuestion'
  | 'halfAnswers'
  | 'changeQuestion'
  | 'resetTimer'
  | 'updateExpiredQuiz';

export default (): Record<FunctionName, firebase.functions.HttpsCallable> => {
  const firebaseApp = useFirebaseApp();

  const answerQuestion = firebaseApp
    .functions('europe-west3')
    .httpsCallable('answerQuestion');

  const nextQuestion = firebaseApp
    .functions('europe-west3')
    .httpsCallable('nextQuestion');

  const halfAnswers = firebaseApp
    .functions('europe-west3')
    .httpsCallable('halfAnswers');

  const changeQuestion = firebaseApp
    .functions('europe-west3')
    .httpsCallable('changeQuestion');

  const resetTimer = firebaseApp
    .functions('europe-west3')
    .httpsCallable('resetTimer');

  const updateExpiredQuiz = firebaseApp
    .functions('europe-west3')
    .httpsCallable('updateExpiredQuiz');

  return {
    answerQuestion,
    nextQuestion,
    halfAnswers,
    changeQuestion,
    resetTimer,
    updateExpiredQuiz,
  };
};
