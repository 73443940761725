import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useFirestore, useUser } from 'reactfire';
import AuthenticatedLayout from '../AuthenticatedLayout';
import GuestLayout from '../GuestLayout';
import HomeScreen from '../HomeScreen';
import QuizScreen from '../../Quiz/QuizScreen';
import NotFoundScreen from '../NotFoundScreen';
import PlayAgainScreen from '../../Quiz/PlayAgainScreen';
import LegalScreen, { LegalScreenProps } from '../../Legal/LegalScreen';
import Storybook from '../Storybook';
import SignInScreen from '../../User/SignInScreen';
import { ConfigContextProvider } from '../../Quiz/ConfigContext';
import { LEGAL_PAGE_IDS } from '../../../common/constants';

const Root: React.FC = () => {
  const { data: user } = useUser();
  const isLogged = !!user;
  const firestore = useFirestore();

  // created a user if it didn't exist before
  useEffect(() => {
    const run = async () => {
      if (!user) {
        return;
      }
      const userRef = firestore.collection('users').doc(user.uid);
      const userSnap = await userRef.get();
      if (!userSnap.exists) {
        await userRef.set({});
      }
    };
    run();
  }, [user, firestore]);

  if (isLogged) {
    return (
      <AuthenticatedLayout>
        <ConfigContextProvider>
          <Switch>
            <Route exact path="/storybook" component={Storybook} />
            <Route exact path="/" component={HomeScreen} />
            <Route exact path="/quiz/:id" component={QuizScreen} />
            <Route exact path="/play-again" component={PlayAgainScreen} />
            <Route
              exact
              path="/privacy-policy"
              component={() => (
                <LegalScreen
                  pageId={
                    LEGAL_PAGE_IDS.privacyPolicy as LegalScreenProps['pageId']
                  }
                />
              )}
            />
            <Route
              exact
              path="/cookies-policy"
              component={() => (
                <LegalScreen
                  pageId={
                    LEGAL_PAGE_IDS.cookiesPolicy as LegalScreenProps['pageId']
                  }
                />
              )}
            />
            <Route
              exact
              path="/terms-and-conditions"
              component={() => (
                <LegalScreen
                  pageId={
                    LEGAL_PAGE_IDS.termsAndConditions as LegalScreenProps['pageId']
                  }
                />
              )}
            />
            <Route path="*" component={NotFoundScreen} />
          </Switch>
        </ConfigContextProvider>
      </AuthenticatedLayout>
    );
  }

  return (
    <GuestLayout>
      <Switch>
        <Route exact path="/storybook" component={Storybook} />
        <Route
          exact
          path="/terms-and-conditions"
          component={() => (
            <LegalScreen
              pageId={
                LEGAL_PAGE_IDS.termsAndConditions as LegalScreenProps['pageId']
              }
            />
          )}
        />
        <Route path="*" component={SignInScreen} />
      </Switch>
    </GuestLayout>
  );
};

export default Root;
