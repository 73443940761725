import React from 'react';
import { alpha, Typography, useTheme } from '@mui/material';
import IconButton, { IconButtonProps } from '../IconButton';
import Box from '../Box';
import useStyles from './useStyles';
import boosterIcons from './boosterIcons';
import { ITheme } from '../../../common/theme';

interface BoosterProps extends IconButtonProps {
  iconType: string;
  amount: number;
}

const Booster: React.FC<BoosterProps> = ({
  disabled = false,
  children,
  iconType,
  amount,
  ...iconButtonProps
}) => {
  const classes = useStyles({ disabled });
  const icon = boosterIcons[iconType];
  const theme = useTheme<ITheme>();
  const maskColor = disabled
    ? alpha(theme.palette.common.black, 0.5)
    : theme.palette.common.darkBlue;
  const boosterIcon = disabled ? icon.disabled : icon.active;
  const showAmountLabel = !disabled && amount > 1;

  return (
    <IconButton
      {...iconButtonProps}
      size="large"
      disabled={disabled}
      disableRipple
    >
      {showAmountLabel && (
        <Box
          width={{ xs: 27, md: 32 }}
          height={{ xs: 27, md: 32 }}
          borderRadius={50}
          position="absolute"
          top={-5}
          right={-5}
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="common.red"
          zIndex={4}
        >
          <Typography variant="body1">{amount}</Typography>
        </Box>
      )}
      <Box
        width={1}
        height={1}
        zIndex={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {boosterIcon}
      </Box>
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        borderRadius="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex={1}
      >
        <Box
          className={classes.darkMask}
          width={0.72}
          height={0.72}
          borderRadius="100%"
          bgcolor={maskColor}
        />
        <Box
          className={classes.topMask}
          position="absolute"
          top="10%"
          right="10%"
          bottom="10%"
          left="10%"
          borderRadius="100%"
        />
      </Box>
    </IconButton>
  );
};

export default Booster;
