import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'reactfire';
import Loader from '../../Unknown/Loader';
import { UIContext } from '../../Unknown/UIContext';

const SignInScreen: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const { setAlert } = useContext(UIContext);

  const handleSignUp = React.useCallback(async () => {
    try {
      await auth.signInAnonymously();
      history.push('/');
    } catch (error) {
      if (error instanceof Error) {
        setAlert({
          show: true,
          severity: 'warning',
          message: error.toString(),
        });
      }
    }
  }, [auth, history, setAlert]);

  useEffect(() => {
    handleSignUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader />;
};

export default SignInScreen;
