import React, { useEffect, useState, CSSProperties } from 'react';
import Box from '../Box';
import ScoreItem from '../ScoreItem';

interface ScoreBoardProps {
  // eslint-disable-next-line
  score?: number;
  style: CSSProperties;
}

const DELAY = 5;
const DIGITS_MAX = 5;

const ScoreBoard: React.FC<ScoreBoardProps> = ({ score = 0, style = {} }) => {
  const [scoreDigit, setScoreDigit] = useState(score);
  useEffect(() => {
    const interval = setInterval(() => {
      if (scoreDigit < score) {
        setScoreDigit((digit) => digit + 1);
      } else {
        clearInterval(interval);
      }
    }, DELAY);

    return () => {
      clearInterval(interval);
    };
  }, [score, scoreDigit, setScoreDigit]);

  const digits = String(scoreDigit).split('');

  while (digits.length < DIGITS_MAX) {
    digits.unshift('');
  }

  return (
    <div
      id="scoreboard"
      style={{
        display: 'flex',
        flex: '1',
        justifyContent: 'center',
        ...style,
      }}
    >
      {digits.map((digit, index) => {
        const isFirst = index === 0;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index} ml={isFirst ? 0 : { xs: 0.5, md: 1.5 }}>
            <ScoreItem digit={digit} />
          </Box>
        );
      })}
    </div>
  );
};

export default ScoreBoard;
