import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  useFirebaseApp,
  useUser,
  useFirestore,
  useFirestoreCollection,
} from 'reactfire';
import { QuizPublic } from '../../../../types';
import mapFirestoreSnapshot from '../../../firebase/common/mapFirestoreSnapshot';
import Loader from '../../Unknown/Loader';
import Box from '../../Unknown/Box';
import Typography from '../../Unknown/Typography';

const DELAY = 3000;

const HomeScreen: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const firebase = useFirebaseApp();
  const firestore = useFirestore();
  const { data: userData } = useUser();
  const history = useHistory();
  const userId = userData.uid;

  const currentUserQuizzesRef = firestore
    .collection('quizzesPublic')
    .where('userId', '==', userId)
    .where('status', '==', 'in_progress')
    .limit(1);

  const { data: quizData, status } = useFirestoreCollection<QuizPublic>(
    currentUserQuizzesRef,
  );

  const startQuiz = firebase
    .functions('europe-west3')
    .httpsCallable('startQuiz');

  useEffect(() => {
    const onStartQuiz = async () => {
      try {
        await startQuiz();
      } catch (error) {
        if (error instanceof Error) {
          setErrorMessage(error.message);
          setTimeout(() => {
            history.push('/');
          }, DELAY);
        }
      }
    };

    onStartQuiz();
  }, [history, startQuiz]);

  if (status !== 'success') return null;

  if (!quizData.empty) {
    const [currentQuiz] = mapFirestoreSnapshot(quizData);
    return <Redirect to={`/quiz/${currentQuiz.id}`} />;
  }

  return (
    <>
      {errorMessage ? (
        <Box
          minHeight="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h1">{errorMessage}</Typography>
        </Box>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default HomeScreen;
