import React from 'react';
import Box from '../Box';
import ProgressItem from '../ProgressItem';
import progressBarItems from './progressBarItems';

interface ProgressBarProps {
  activeItemNumber: number;
  milestoneIndexes: number[];
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  activeItemNumber,
  milestoneIndexes,
}) => {
  return (
    <Box
      position="relative"
      height={{ xs: 50, sm: 66, md: 87, xl: 107 }}
      display="flex"
      justifyContent="stretch"
      alignItems="center"
    >
      {progressBarItems.map(
        ({ number, activeBackground, size }, index, array) => {
          const isLast = index === array.length - 1;
          const isMilestone = isLast || milestoneIndexes.includes(index);

          return (
            <ProgressItem
              key={`progress-item_${number}`}
              size={size}
              number={number}
              active={number <= activeItemNumber}
              currentActive={number === activeItemNumber}
              isMilestone={isMilestone}
              divider={!isLast}
              activeBackground={activeBackground}
            />
          );
        },
      )}
    </Box>
  );
};

export default ProgressBar;
