import React, { useContext, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import {
  useFirebaseApp,
  useUser,
  useFirestore,
  useFirestoreCollectionData,
} from 'reactfire';
import { useMediaQuery, useTheme } from '@mui/material';
import { ConfigContext } from '../../Quiz/ConfigContext';
import Logo from '../Logo';
import PrizePreview from '../PrizePreview';
import Box from '../Box';
import Typography from '../Typography';
import Container from '../Container';
import SocialLinks from '../SocialLinks';
import Countdown from '../Countdown';
import Footer from '../Footer';
import MobileMenu from '../MobileMenu';
import messages from './messages';
import useStyles from './useStyles';
import Button from '../Button';
import menuItems from './menuItems';
import RulesPopUp from '../../Quiz/RulesPopUp';
import Snackbar from '../Snackbar';
import { QuizPublic } from '../../../../types';
import useDontShowRules from '../../Quiz/RulesPopUp/useDontShowRules';
import { DEFAULT_LOCALE } from '../../../common/constants';

const HomeScreen: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const delayMs = 5000;
  const firebase = useFirebaseApp();
  const firestore = useFirestore();
  const { data: userData } = useUser();
  const {
    config: { prize, endAt, homePageText },
  } = useContext(ConfigContext);
  const [rulesPopUpOpen, setRulesPopUpOpen] = useState(false);
  const [expiredQuizId, setExpiredQuizId] = useState<string | null>(null);
  const { dontShowRules } = useDontShowRules();
  const locale = intl.locale || DEFAULT_LOCALE;
  const textParagraphs = homePageText[locale];

  const userId = userData.uid;

  const currentUserQuizzesRef = firestore
    .collection('quizzesPublic')
    .where('userId', '==', userId)
    .where('status', '==', 'in_progress')
    .limit(1);

  const { data: quizData, status } = useFirestoreCollectionData<QuizPublic>(
    currentUserQuizzesRef,
    {
      idField: 'id',
    },
  );

  const onOpenRules = () => {
    setRulesPopUpOpen(true);
  };

  const onCloseRules = () => {
    setRulesPopUpOpen(false);
  };

  const startQuiz = firebase
    .functions('europe-west3')
    .httpsCallable('startQuiz');

  const updateExpiredQuiz = firebase
    .functions('europe-west3')
    .httpsCallable('updateExpiredQuiz');

  const failActiveQuizzes = async () => {
    if (quizData?.length) {
      setExpiredQuizId(quizData[0].id);
      try {
        const [activeQuiz] = quizData;
        if (!activeQuiz) return;
        await updateExpiredQuiz({ quizId: activeQuiz.id });
      } catch (error) {
        throw new Error(intl.formatMessage(messages.quizErrorMessage));
      }
    }
  };

  const onQuizStart = async () => {
    setIsLoading(true);
    try {
      await failActiveQuizzes();
      await startQuiz();
      setIsStarted(true);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(intl.formatMessage(messages.quizErrorMessage));
      setIsStarted(false);
    }
  };

  const onStartClick = () => {
    if (!dontShowRules) {
      onOpenRules();
    } else {
      onQuizStart();
    }
  };

  const onSnackbarClose = () => {
    setErrorMessage(null);
  };

  const startButtonText = isLoading
    ? messages.loadingButtonLabel
    : messages.startButtonLabel;

  if (status !== 'success') return null;

  if (quizData?.length && isStarted) {
    const [currentQuiz] = quizData;
    if (currentQuiz.id !== expiredQuizId) {
      return <Redirect to={`/quiz/${currentQuiz.id}`} />;
    }
  }

  return (
    <Box className={classes.screen} minHeight="100vh">
      <Container maxWidth="xl">
        <Box
          pt={{ xs: 0, md: 2 }}
          display="flex"
          justifyContent={{ md: 'center' }}
          position="relative"
        >
          {isMobile ? (
            <MobileMenu menuItems={menuItems} />
          ) : (
            <>
              <Logo />
              <Box position="absolute" top={35} right={10}>
                <SocialLinks />
              </Box>
            </>
          )}
        </Box>
        <Box
          py={2}
          maxWidth={1520}
          mx="auto"
          display="flex"
          flexDirection={{ xs: 'column', lg: 'row' }}
        >
          <Box pr={{ lg: 4 }} textAlign="center">
            <Typography variant="h3">
              <FormattedMessage {...messages.title} />
            </Typography>
            {!isMobile &&
              textParagraphs.map(({ text, color }) => {
                return (
                  <Typography
                    mt={{ xs: 4, md: 6, xl: 8 }}
                    variant="body1"
                    color={color}
                  >
                    {text}
                  </Typography>
                );
              })}
          </Box>
          <Box
            mt={{ xs: 10, lg: 0 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box position="relative" ml={{ lg: 22 }}>
              <PrizePreview
                prizeName={prize?.name}
                imageSrc={prize?.imageUrl}
              />
              <Box
                width={1}
                position="absolute"
                bottom={0}
                display="flex"
                justifyContent="center"
              >
                <Countdown expiresAt={endAt?.toMillis()} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt={8} mx="auto" width={{ xs: 520, md: 615 }} maxWidth={1}>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={onStartClick}
            fullWidth
          >
            <FormattedMessage {...startButtonText} />
          </Button>
          <RulesPopUp
            open={rulesPopUpOpen}
            onQuizStart={onQuizStart}
            onClose={onCloseRules}
          />
        </Box>
        {isMobile &&
          textParagraphs.map(({ text, color }) => {
            return (
              <Typography
                mt={{ xs: 4, md: 6, xl: 8 }}
                variant="body1"
                color={color}
                textAlign="center"
              >
                {text}
              </Typography>
            );
          })}
        <Box pt={{ xs: 5, md: 10 }} pb={10}>
          <Footer />
        </Box>
        {errorMessage && (
          <Snackbar
            open={Boolean(errorMessage)}
            autoHideDuration={delayMs}
            onClose={onSnackbarClose}
            message={errorMessage}
          />
        )}
      </Container>
    </Box>
  );
};

export default HomeScreen;
