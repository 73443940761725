import React from 'react';
import { Drawer as DrawerBase, DrawerProps } from '@mui/material';

const Drawer: React.FC<DrawerProps> = ({ children, ...props }) => {
  return (
    <DrawerBase {...props} elevation={0} anchor="left">
      {children}
    </DrawerBase>
  );
};

export default Drawer;
