/* eslint-disable react/require-default-props */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Box from '../Box';
import Button from '../Button';
import Drawer from '../Drawer';
import IconButton from '../IconButton';
import Typography from '../Typography';
import useStyles from './useStyles';
import messages from './messages';
import RulesPopUp from '../../Quiz/RulesPopUp';

interface MenuItem {
  title: { id: string; defaultMessage: string };
  link: string;
}

export interface MobileMenuProps {
  menuItems?: MenuItem[];
  onHomeClick?: () => void;
  onExitClick?: () => void;
  homeButtonLabel?: string;
  disableRules?: boolean;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  menuItems,
  onHomeClick,
  onExitClick,
  homeButtonLabel,
  disableRules = false,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [rulesOpen, setRulesOpen] = useState(false);
  const classes = useStyles();

  const openMobileMenu = () => {
    setMobileMenuOpen(true);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const openRules = () => {
    setRulesOpen(true);
  };

  const closeRules = () => {
    setRulesOpen(false);
  };

  return (
    <Box>
      <IconButton
        onClick={openMobileMenu}
        classes={{ root: classes.burgerIcon }}
      >
        <Box display="flex" flexDirection="column">
          <Box
            width={39}
            height={8}
            borderRadius={10}
            bgcolor="common.darklyBlue"
          />
          <Box
            width={39}
            height={8}
            mt={1}
            borderRadius={10}
            bgcolor="common.darklyBlue"
          />
          <Box
            width={39}
            height={8}
            mt={1}
            borderRadius={10}
            bgcolor="common.darklyBlue"
          />
        </Box>
      </IconButton>
      <Drawer open={mobileMenuOpen} onClose={closeMobileMenu}>
        <Box px={6}>
          <IconButton
            onClick={closeMobileMenu}
            classes={{ root: classes.burgerIcon }}
          >
            <Box display="flex" flexDirection="column">
              <Box
                width={39}
                height={8}
                borderRadius={10}
                bgcolor="common.darklyBlue"
              />
            </Box>
          </IconButton>
          <Box mt={6}>
            <Box mb={13}>
              <Button
                classes={{ root: classes.menuButton }}
                variant="text"
                color="primary"
                endIcon=">"
                onClick={onHomeClick || closeMobileMenu}
                fullWidth
              >
                {homeButtonLabel || (
                  <FormattedMessage {...messages.homeButtonLabel} />
                )}
              </Button>
            </Box>
            <Box mb={13}>
              <Button
                classes={{ root: classes.menuButton }}
                variant="text"
                color="primary"
                endIcon=">"
                onClick={openRules}
                fullWidth
                disabled={disableRules}
              >
                <FormattedMessage {...messages.rulesButtonLabel} />
              </Button>
              <RulesPopUp open={rulesOpen} onClose={closeRules} />
            </Box>
            {menuItems &&
              menuItems.map(({ title, link }, index) => {
                return (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={`link_${index}`}
                    mb={13}
                  >
                    <Link to={link} className={classes.link}>
                      <Typography variant="h3">
                        <FormattedMessage {...title} />
                      </Typography>
                      <Typography variant="h3">&gt;</Typography>
                    </Link>
                  </Box>
                );
              })}
            {onExitClick && (
              <Button
                classes={{ root: classes.menuButton }}
                variant="text"
                color="primary"
                endIcon=">"
                onClick={onExitClick}
                fullWidth
              >
                <FormattedMessage {...messages.exitButtonLabel} />
              </Button>
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileMenu;
