import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';
import firebaseApp from '../../../common/firebaseApp';
import theme from '../../../common/theme';
import IntlProvider from '../IntlProvider';
import Root from '../Root';
import { UIContextProvider } from '../UIContext';

const App: React.FC = () => {
  return (
    <FirebaseAppProvider firebaseApp={firebaseApp}>
      <ThemeProvider theme={theme}>
        <IntlProvider>
          <Router
            getUserConfirmation={() => {
              /* Empty callback to block the default browser prompt */
            }}
            basename={process.env.PUBLIC_URL || '/'}
          >
            <CssBaseline />
            <UIContextProvider>
              <Root />
            </UIContextProvider>
          </Router>
        </IntlProvider>
      </ThemeProvider>
    </FirebaseAppProvider>
  );
};

export default App;
