import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Unknown.CongratulationBanner.title',
    defaultMessage: 'Congratulations',
  },
  gameOver: {
    id: 'Unknown.CongratulationBanner.gameOver',
    defaultMessage: 'Game over',
  },
  entriesText: {
    id: 'Unknown.CongratulationBanner.entriesText',
    defaultMessage: 'You have {entriesAmount} entries',
  },
  emailPlaceholder: {
    id: 'Unknown.CongratulationBanner.emailPlaceholder',
    defaultMessage: 'Enter Email...',
  },
  text: {
    id: 'Unknown.CongratulationBanner.text',
    defaultMessage:
      'Please enter your email below so we can enter you into the prize draw and send you exclusive offers and the latest info about Tada Games. We’ll always treat your details with the utmost care and will never sell them to other companies.',
  },
  textToContact: {
    id: 'Unknown.CongratulationBanner.textToContact',
    defaultMessage:
      'Please let us know if you would like us to contact you or not by selecting one of the options below.',
  },
  agree: {
    id: 'Unknown.CongratulationBanner.agree',
    defaultMessage: 'Yes please. I’d like to hear about offers and services.',
  },
  disagree: {
    id: 'Unknown.CongratulationBanner.disagree',
    defaultMessage:
      'No thanks. I don’t want to hear about offers and services.',
  },
  checkbox: {
    id: 'Unknown.CongratulationBanner.checkbox',
    defaultMessage: 'I agree the ',
  },
  terms: {
    id: 'Unknown.CongratulationBanner.terms',
    defaultMessage: 'terms and conditions',
  },
  submitLabel: {
    id: 'Unknown.CongratulationBanner.submitLabel',
    defaultMessage: 'Submit',
  },
  exitMessage: {
    id: 'Unknown.CongratulationBanner.exitMessage',
    defaultMessage: 'Are you sure you want to exit?',
  },
  playAgainMessage: {
    id: 'Unknown.CongratulationBanner.playAgainMessage',
    defaultMessage: 'Would you like to play again?',
  },
  yes: {
    id: 'Unknown.CongratulationBanner.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'Unknown.CongratulationBanner.no',
    defaultMessage: 'No',
  },
  termsIsReq: {
    id: 'Unknown.CongratulationBanner.termsIsReq',
    defaultMessage: 'Terms and conditions is required',
  },
});
