import React, { useState } from 'react';
import Box from '../Box';
import Typography from '../Typography';
import PrizePreview from '../PrizePreview';
import carImage from './bmw-image.png';
import Checkbox from '../Checkbox';
import Radio from '../Radio';
import RadioGroup from '../RadioGroup';
import FormControlLabel from '../FormControlLabel';
import SocialLinks from '../SocialLinks';
import Logo from '../Logo';
import TextField from '../TextField';
import ProgressBar from '../ProgressBar';
import Button from '../Button';
import ConfirmModal from '../ConfirmModal';
import CongratulationPopUp from '../../Quiz/CongratulationPopUp';
import IconButton from '../IconButton';
import { ExitIcon } from '../Icons';
import Link from '../Link';
import Countdown from '../Countdown';
import ScoreBoard from '../ScoreBoard';
import Booster from '../Booster';
import Footer from '../Footer';
import RulesPopUp from '../../Quiz/RulesPopUp';

const Storybook: React.FC = () => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [congratulationPopUpOpen, setCongratulationPopUpOpen] = useState(false);
  const [rulesPopUpOpen, setRulesPopUpOpen] = useState(false);
  const [gameOverPopUpOpen, setGameOverPopUpOpen] = useState(false);
  const expiresAt = new Date('Jan 10, 2022 00:00:00').getTime();
  const [activeItem, setActiveItem] = useState(1);
  const [score, setScore] = useState(0);
  const buttonScore = {
    sm: 10,
    md: 50,
    lg: 100,
  };

  const increaseItemNumber = () => {
    if (activeItem >= 20) return;
    setActiveItem((number) => number + 1);
  };

  return (
    <Box p={2}>
      <Typography variant="h2">Logo</Typography>
      <Logo />
      <Typography variant="h2">Social Links</Typography>
      <Box mt={4}>
        <SocialLinks />
      </Box>
      <Typography mt={2} variant="h2">
        Checkbox
      </Typography>
      <Box mt={3}>
        <FormControlLabel
          control={<Checkbox />}
          label={
            <Typography color="text.secondary" fontSize={{ xs: 20, md: 25 }}>
              Don&apos;t show this again
            </Typography>
          }
        />
      </Box>
      <Typography mt={2} variant="h2">
        Radio
      </Typography>
      <Box mt={3}>
        <RadioGroup defaultValue="agree">
          <FormControlLabel
            value="agree"
            control={<Radio />}
            label={
              <Typography color="text.secondary" fontSize={{ xs: 20, md: 25 }}>
                Yes please. I’d like to hear about offers and services.
              </Typography>
            }
          />
          <FormControlLabel
            value="disagree"
            control={<Radio />}
            label={
              <Typography color="text.secondary" fontSize={{ xs: 20, md: 25 }}>
                No thanks. I don’t want to hear about offers and services.
              </Typography>
            }
          />
        </RadioGroup>
      </Box>
      <Typography variant="h2">Prize</Typography>
      <Box mt={4} ml={{ xs: 12, sm: 16, md: 22, lg: 24 }}>
        <PrizePreview prizeName="2017 bmw m3" imageSrc={carImage} />
      </Box>
      <Typography variant="h2">Countdown</Typography>
      <Box mt={3} p={4} bgcolor="#F0CFA6">
        <Countdown expiresAt={expiresAt} />
      </Box>
      <Typography mt={2} variant="h1">
        Heading 1
      </Typography>
      <Typography mt={2} variant="h2">
        Heading 2
      </Typography>
      <Typography mt={2} variant="h3">
        Heading 3
      </Typography>
      <Typography mt={2} variant="h4">
        Heading 4
      </Typography>
      <Typography mt={2} variant="subtitle1">
        Subtitle 1
      </Typography>
      <Typography mt={2} variant="body1">
        Text 1
      </Typography>
      <Typography mt={2} variant="body2">
        Text 2
      </Typography>
      <Typography mt={2} variant="h2">
        Button
      </Typography>
      <Box mt={2} width={415} maxWidth={1}>
        <Button size="large" color="primary" variant="contained" fullWidth>
          Read More
        </Button>
      </Box>
      <Box mt={2} width={{ xs: 420, md: 615 }} maxWidth={1}>
        <Button size="large" color="secondary" variant="contained" fullWidth>
          Yes
        </Button>
      </Box>
      <Box mt={2} width={{ xs: 420, md: 615 }} maxWidth={1}>
        <Button size="large" color="error" variant="contained" fullWidth>
          No
        </Button>
      </Box>
      <Box maxWidth={{ xs: 520, lg: 615 }} mt={2}>
        <Button size="small" color="primary" variant="contained" fullWidth>
          D. Richard Whittington
        </Button>
      </Box>
      <Box maxWidth={{ xs: 520, lg: 615 }} mt={2}>
        <Button size="small" color="error" variant="contained" fullWidth>
          A. Catherine Parr
        </Button>
      </Box>
      <Box maxWidth={{ xs: 520, lg: 615 }} mt={2}>
        <Button size="small" color="success" variant="contained" fullWidth>
          B. Sir Walter Raleigh
        </Button>
      </Box>
      <Box mt={2} width={{ xs: 520, md: 615 }} maxWidth={1}>
        <Button color="secondary" variant="outlined" fullWidth>
          START
        </Button>
      </Box>
      <Box mt={2}>
        <Button size="small" color="primary" variant="outlined">
          Next
        </Button>
      </Box>
      <Box mt={2}>
        <Button size="small" color="primary" variant="outlined" disabled>
          Next
        </Button>
      </Box>
      <Box mt={2} display="flex">
        <IconButton color="primary" onClick={() => setRulesPopUpOpen(true)}>
          <Typography fontSize={{ xs: 40, md: 50 }} fontWeight={900}>
            ?
          </Typography>
        </IconButton>
        <RulesPopUp
          open={rulesPopUpOpen}
          onClose={() => setRulesPopUpOpen(false)}
        />
        <Box ml={4}>
          <IconButton color="primary">
            <Box pt={1} width={{ xs: 35, md: 45 }}>
              <ExitIcon />
            </Box>
          </IconButton>
        </Box>
        <Box ml={4}>
          <IconButton color="primary" disabled>
            <Typography fontSize={{ xs: 40, md: 50 }} fontWeight={900}>
              ?
            </Typography>
          </IconButton>
        </Box>
      </Box>
      <Box my={3}>
        <Link href="/" color="text.primary" fontSize={{ xs: 20, md: 25 }}>
          Terms and Conditions
        </Link>
      </Box>
      <Typography mt={2} variant="h2">
        Progress Bar
      </Typography>
      <Box mt={3}>
        <ProgressBar milestoneIndexes={[9]} activeItemNumber={activeItem} />
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={increaseItemNumber}
            size="small"
          >
            + 1
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setActiveItem(1)}
            size="small"
          >
            reset
          </Button>
        </Box>
      </Box>
      <Typography mt={3} variant="h2">
        TextField
      </Typography>
      <Box py={4} width={{ xs: 505, md: 814 }} maxWidth={1}>
        <TextField fullWidth placeholder="Enter Email..." />
      </Box>
      <Typography mt={2} variant="h2">
        Modal
      </Typography>
      <Box mt={3}>
        <Button
          onClick={() => setConfirmModalOpen(true)}
          variant="outlined"
          color="primary"
          size="small"
        >
          Open Confirm Modal
        </Button>
        <ConfirmModal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          message="Would you like to play again?"
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          onConfirm={() => setConfirmModalOpen(false)}
          onCancel={() => setConfirmModalOpen(false)}
        />
      </Box>
      <Box mt={3}>
        <Button
          onClick={() => setCongratulationPopUpOpen(true)}
          variant="outlined"
          color="primary"
          size="small"
        >
          Open Congratulation
        </Button>

        <CongratulationPopUp
          open={congratulationPopUpOpen}
          entries={score}
          prizeImageSrc={carImage}
          quizResult="succeeded"
          imageAlt="Car BMW M3 Sedan"
        />
      </Box>
      <Box mt={3}>
        <Button
          onClick={() => setGameOverPopUpOpen(true)}
          variant="outlined"
          color="primary"
        >
          Open Game Over
        </Button>
        <CongratulationPopUp
          open={gameOverPopUpOpen}
          entries={score}
          prizeImageSrc={carImage}
          quizResult="failed"
          imageAlt="Car BMW M3 Sedan"
        />
      </Box>
      <Typography variant="h2">Booster</Typography>
      <Box mt={4} display="flex">
        <Box>
          <Booster amount={1} iconType="half_answers_booster" />
        </Box>
        <Box ml={{ xs: 3, md: 14 }}>
          <Booster amount={2} iconType="new_question_booster" />
        </Box>
        <Box ml={{ xs: 3, md: 14 }}>
          <Booster amount={1} iconType="new_timer_booster" />
        </Box>
      </Box>
      <Box mt={4} display="flex">
        <Box>
          <Booster amount={1} iconType="half_answers_booster" disabled />
        </Box>
        <Box ml={{ xs: 3, md: 14 }}>
          <Booster amount={0} iconType="new_question_booster" disabled />
        </Box>
        <Box ml={{ xs: 3, md: 14 }}>
          <Booster amount={1} iconType="new_timer_booster" disabled />
        </Box>
      </Box>
      <Typography variant="h2">Score Board</Typography>
      <Box mt={3} display="flex">
        <ScoreBoard score={score} style={{}} />
        <Box ml={4} display="flex">
          <Button
            onClick={() => setScore(score + buttonScore.sm)}
            variant="outlined"
            color="primary"
            size="small"
          >
            {buttonScore.sm}
          </Button>
        </Box>

        <Box ml={4} display="flex">
          <Button
            onClick={() => setScore(score + buttonScore.md)}
            variant="outlined"
            color="primary"
            size="small"
          >
            {buttonScore.md}
          </Button>
        </Box>

        <Box ml={4} display="flex">
          <Button
            onClick={() => setScore(score + buttonScore.lg)}
            variant="outlined"
            color="primary"
            size="small"
          >
            {buttonScore.lg}
          </Button>
        </Box>
      </Box>
      <Box my={9}>
        <Footer />
      </Box>
    </Box>
  );
};

export default Storybook;
