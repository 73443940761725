import { makeStyles } from '@mui/styles';
import { ITheme } from '../../../common/theme';

export default makeStyles((theme: ITheme) => ({
  navLink: {
    color: theme.palette.text.primary,
    fontsize: 20,
    [theme.breakpoints.up('md')]: {
      fontSize: 25,
    },
  },
}));
