import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Box from '../Box';
import Typography from '../Typography';
import getTimerValues from './getTimerValues';
import useStyles from './useStyles';
import messages from './messages';

interface CountdownProps {
  expiresAt: number;
}

const Countdown: React.FC<CountdownProps> = ({ expiresAt }) => {
  const [timeLeft, setTimeLeft] = useState(expiresAt - Date.now());
  const classes = useStyles();
  const intl = useIntl();
  const { days, hours, minutes, seconds } = getTimerValues(timeLeft);

  const timerData = [
    {
      label: intl.formatMessage(messages.days),
      value: days,
    },
    {
      label: intl.formatMessage(messages.hours),
      value: hours,
    },
    {
      label: intl.formatMessage(messages.minutes),
      value: minutes,
    },
    {
      label: intl.formatMessage(messages.seconds),
      value: seconds,
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(expiresAt - Date.now());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <Box display="flex" className={classes.timer}>
      {timerData.map(({ value, label }, index) => {
        const isFirst = index === 0;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {!isFirst && (
              <Typography
                letterSpacing="0.1em"
                fontSize={{ xs: 30, sm: 50, md: 65, xl: 80 }}
                lineHeight={1}
                fontWeight={700}
                color="text.secondary"
              >
                :
              </Typography>
            )}
            <Box textAlign="center">
              <Typography
                letterSpacing="0.1em"
                fontSize={{ xs: 30, sm: 50, md: 65, xl: 80 }}
                lineHeight={1}
                fontWeight={700}
                color="text.secondary"
              >
                {value}
              </Typography>
              <Typography
                mt={{ xs: 1, md: 2 }}
                color="text.secondary"
                fontSize={{ xs: 18, sm: 25, md: 30 }}
                fontWeight={700}
              >
                {label}
              </Typography>
            </Box>
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default Countdown;
