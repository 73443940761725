import React from 'react';
import { ReactComponent as LogoIcon } from './logo.svg';
import Box from '../Box';

const Logo: React.FC = () => {
  return (
    <Box
      width={{ xs: 148, md: 186, xl: 224 }}
      height={{ xs: 72, md: 95, xl: 118 }}
    >
      <LogoIcon />
    </Box>
  );
};

export default Logo;
