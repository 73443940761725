const getFormattedValue = (value: number): string => {
  return value > 9 ? `${value}` : `0${value}`;
};

interface TimerValues {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

const getTimerValues = (initialTimeLeft: number): TimerValues => {
  const MS_IN_SEC = 1000;
  const MS_IN_MIN = MS_IN_SEC * 60;
  const MS_IN_HOUR = MS_IN_MIN * 60;
  const MS_IN_DAY = MS_IN_HOUR * 24;

  let timeLeft = initialTimeLeft;
  const days = Math.floor(timeLeft / MS_IN_DAY);
  timeLeft -= days * MS_IN_DAY;

  const hours = Math.floor(timeLeft / MS_IN_HOUR);
  timeLeft -= hours * MS_IN_HOUR;

  const minutes = Math.floor(timeLeft / MS_IN_MIN);
  timeLeft -= minutes * MS_IN_MIN;

  const seconds = Math.floor(timeLeft / MS_IN_SEC);

  const timerValues = {
    days,
    hours,
    minutes,
    seconds,
  };

  const formattedValues = Object.entries(timerValues).map(([key, value]) => [
    key,
    getFormattedValue(value),
  ]);

  return Object.fromEntries(formattedValues);
};

export default getTimerValues;
