import { createTheme, darken, Theme, ThemeOptions } from '@mui/material';
import { CommonColors, Palette } from '@mui/material/styles/createPalette';

interface ICommonColors extends CommonColors {
  orange: string;
  red: string;
  darkBlue: string;
  darklyBlue: string;
  maroon: string;
}

interface IPalette extends Palette {
  common: ICommonColors;
}

interface IThemeOptions extends ThemeOptions {
  palette: IPalette;
}

export interface ITheme extends Theme {
  palette: IPalette;
}

const colors = {
  primaryMain: '#236C7B',
  primaryDark: '#0B495D',
  secondaryMain: '#FB8801',
  textPrimary: '#055061',
  textSecondary: '#000000',
  textGrey: '#7E7E7E',
  white: '#FFFFFF',
  error: '#E34949',
  successMain: '#9BDC00',
  successDark: '#3AB02A',
  darkGray: '#4E4E4E',
  mediumGray: '#757575',
  darklyGray: '#595959',
  lightGray: '#C5C5C5',
  gold: '#FFBC6D',
  black: '#000000',
  border: '#055061',
  orange: '#F08F26',
  red: '#FF0000',
  darkBlue: '#003541',
  darklyBlue: '#094053',
  maroon: '#610505',
  linkText: '#0066ff',
};

const defaultTheme = createTheme({
  spacing: 5,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1240,
      xl: 1920,
    },
  },
});

const shadows = {
  xs: '0px 0px 4px 0px #000000',
  s: '0px 4px 4px 0px #000000',
  buttonConvex: 'inset 0px -25px 45px rgba(0, 0, 0, 0.75)',
  button3dEffect:
    // '0px -25px 25px 0px #002A33 inset, 0px 25px 25px 10px #FFFFFF40 inset, 0px 0px 5px 6px #000000 inset, 0px 4px 4px 0px #00000080',
    'none',
};

const theme = createTheme({
  spacing: 5,
  breakpoints: {
    values: {
      xs: 0,
      // xs: 375,
      sm: 600,
      md: 900,
      lg: 1240,
      xl: 1920,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: defaultTheme.spacing(4),
          paddingRight: defaultTheme.spacing(4),
          [defaultTheme.breakpoints.up('sm')]: {
            paddingLeft: defaultTheme.spacing(4),
            paddingRight: defaultTheme.spacing(4),
          },
          [defaultTheme.breakpoints.up('md')]: {
            paddingLeft: defaultTheme.spacing(8),
            paddingRight: defaultTheme.spacing(8),
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: '100%',
          boxShadow: 'none',
          [defaultTheme.breakpoints.up('sm')]: {
            width: 380,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          maxWidth: '100%',
          borderRadius: 100,
          fontSize: 30,
          color: colors.white,
          textTransform: 'none',
        },
        contained: {
          fontFamily: 'Impact',
          borderWidth: 3,
          borderStyle: 'solid',
          borderColor: colors.black,
          color: colors.white,
          boxShadow: shadows.buttonConvex,
          '&:hover': {
            boxShadow: shadows.buttonConvex,
          },
          [defaultTheme.breakpoints.up('lg')]: {
            paddingTop: defaultTheme.spacing(4),
            paddingBottom: defaultTheme.spacing(4),
          },
          '&.Mui-disabled': {
            color: colors.lightGray,
            backgroundColor: colors.mediumGray,
            boxShadow: shadows.buttonConvex,
          },
        },
        sizeLarge: {
          paddingTop: defaultTheme.spacing(4),
          paddingBottom: defaultTheme.spacing(4),
        },
        containedPrimary: {
          backgroundColor: colors.primaryDark,
          '&:hover': {
            backgroundColor: darken(colors.primaryDark, 0.2),
          },
        },
        containedSecondary: {
          backgroundColor: colors.successDark,
          '&:hover': {
            backgroundColor: darken(colors.successDark, 0.2),
          },
        },
        containedSuccess: {
          '&:hover': {
            backgroundColor: colors.successMain,
          },
        },
        containedError: {
          '&.MuiButton-sizeSmall': {
            '&:hover': {
              backgroundColor: colors.error,
            },
          },
        },
        outlined: {
          borderWidth: 5,
          borderTopStyle: 'groove',
          borderRightStyle: 'ridge',
          borderBottomStyle: 'ridge',
          borderLeftStyle: 'groove',
          fontFamily: 'Impact',
          color: colors.white,
          borderColor: colors.gold,
          fontSize: 32,
          boxShadow: shadows.button3dEffect,
          [defaultTheme.breakpoints.up('lg')]: {
            fontSize: 40,
          },
          '&:hover': {
            borderWidth: 5,
            borderTopStyle: 'groove',
            borderRightStyle: 'ridge',
            borderBottomStyle: 'ridge',
            borderLeftStyle: 'groove',
            borderColor: colors.gold,
            boxShadow: shadows.button3dEffect,
          },
          '&.Mui-disabled': {
            background: colors.mediumGray,
            color: colors.white,
            borderTopStyle: 'groove',
            borderRightStyle: 'ridge',
            borderBottomStyle: 'ridge',
            borderLeftStyle: 'groove',
            borderWidth: 5,
            borderColor: colors.lightGray,
            // backgroundImage: 'unset',
          },
        },
        outlinedPrimary: {
          backgroundColor: colors.primaryMain,
          '&:hover': {
            backgroundColor: colors.primaryDark,
          },
        },
        outlinedSecondary: {
          backgroundImage:
            'linear-gradient(180deg, #2E7906 0%, #4C8828 20.83%, #7CD642 44.79%, #0B5000 57.29%, #23A000 100%)',
          '&:hover': {
            backgroundImage:
              'linear-gradient(180deg, #2E7906 0%, #4C8828 20.83%, #9BDC00 44.79%, #0B5000 57.29%, #23A000 100%)',
          },
        },
        containedSizeSmall: {
          justifyContent: 'start',
          paddingLeft: defaultTheme.spacing(7),
          paddingRight: defaultTheme.spacing(7),
          textAlign: 'left',
          fontSize: 20,
          [defaultTheme.breakpoints.up('lg')]: {
            paddingLeft: defaultTheme.spacing(8),
            paddingRight: defaultTheme.spacing(8),
            fontSize: 30,
          },
        },
        outlinedSizeLarge: {
          paddingTop: defaultTheme.spacing(2.5),
          paddingBottom: defaultTheme.spacing(2.5),
          textTransform: 'uppercase',
          [defaultTheme.breakpoints.up('md')]: {
            paddingTop: defaultTheme.spacing(4),
            paddingBottom: defaultTheme.spacing(4),
          },
        },
        outlinedSizeSmall: {
          paddingTop: defaultTheme.spacing(0),
          paddingBottom: defaultTheme.spacing(0),
          paddingLeft: defaultTheme.spacing(6),
          paddingRight: defaultTheme.spacing(6),
          fontSize: 20,
          [defaultTheme.breakpoints.up('lg')]: {
            fontSize: 40,
            paddingLeft: defaultTheme.spacing(10),
            paddingRight: defaultTheme.spacing(10),
          },
        },
        text: {
          minHeight: 'none',
          padding: defaultTheme.spacing(0),
          backgroundColor: 'transparent',
          lineHeight: 1,
          fontSize: 30,
          fontWeight: 400,
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        },
        textPrimary: {
          color: colors.darklyBlue,
        },
        endIcon: {
          marginLeft: defaultTheme.spacing(0),
          marginRight: defaultTheme.spacing(0),
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          marginRight: defaultTheme.spacing(1),
          [defaultTheme.breakpoints.up('md')]: {
            marginRight: defaultTheme.spacing(3),
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colors.white,
          backgroundColor: colors.primaryMain,
          height: 60,
          width: 60,
          borderWidth: 5,
          borderTopStyle: 'groove',
          borderRightStyle: 'ridge',
          borderBottomStyle: 'ridge',
          borderLeftStyle: 'groove',
          borderColor: colors.gold,
          boxShadow: shadows.button3dEffect,
          '&:hover': {
            borderWidth: 5,
            borderTopStyle: 'groove',
            borderRightStyle: 'ridge',
            borderBottomStyle: 'ridge',
            borderLeftStyle: 'groove',
            borderColor: colors.gold,
            backgroundColor: colors.primaryMain,
          },
          [defaultTheme.breakpoints.up('sm')]: {
            // height: 70,
            // width: 70,
            height: 50,
            width: 50,
          },
          [defaultTheme.breakpoints.up('lg')]: {
            // height: 85,
            // width: 85,
            height: 70,
            width: 70,
            '&:hover': {
              backgroundColor: colors.primaryDark,
            },
          },
          '&.Mui-disabled': {
            backgroundColor: colors.darkGray,
            color: colors.white,
            borderWidth: 5,
            borderTopStyle: 'groove',
            borderRightStyle: 'ridge',
            borderBottomStyle: 'ridge',
            borderLeftStyle: 'groove',
            borderColor: colors.lightGray,
          },
        },
        sizeLarge: {
          // width: 70,
          // height: 70,
          width: 50,
          height: 50,
          padding: defaultTheme.spacing(1),
          [defaultTheme.breakpoints.up('sm')]: {
            padding: defaultTheme.spacing(2),
            // width: 88,
            // height: 88,
            width: 68,
            height: 68,
          },
          [defaultTheme.breakpoints.up('md')]: {
            // width: 117,
            // height: 117,
            width: 88,
            height: 88,
          },
          [defaultTheme.breakpoints.up('xl')]: {
            // width: 150,
            // height: 150,
            width: 98,
            height: 98,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginRight: defaultTheme.spacing(1),
          [defaultTheme.breakpoints.up('md')]: {
            marginRight: defaultTheme.spacing(3),
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: '100%',
          maxHeight: '100%',
          background: 'linear-gradient(180deg, #FFFFFF 0%, #FBE3C9 100%)',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(0),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(0),
          justifyContent: 'center',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(0),
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            padding: defaultTheme.spacing(3, 4, 3, 4),
            fontSize: 30,
            lineHeight: '35px',
            [defaultTheme.breakpoints.up('md')]: {
              padding: defaultTheme.spacing(5, 8, 5, 8),
              fontSize: 40,
              lineHeight: '47px',
            },
            '&::placeholder': {
              color: colors.textGrey,
              opacity: 1,
            },
          },
          '& .MuiOutlinedInput-root': {
            backgroundColor: colors.white,
            '& fieldset': {
              borderColor: colors.secondaryMain,
              borderWidth: 5,
              borderRadius: 10,
            },
            '&:hover fieldset': {
              borderColor: colors.secondaryMain,
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.secondaryMain,
              outlineColor: colors.secondaryMain,
              borderWidth: 5,
            },
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.primaryMain,
      dark: colors.primaryDark,
    },
    secondary: {
      main: colors.secondaryMain,
      dark: colors.primaryDark,
    },
    success: {
      main: colors.successMain,
      dark: colors.successDark,
    },
    error: {
      main: colors.error,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
      linkText: colors.linkText,
    },
    grey: {
      600: colors.mediumGray,
      700: colors.darklyGray,
    },
    common: {
      orange: colors.orange,
      red: colors.red,
      darkBlue: colors.darkBlue,
      darklyBlue: colors.darklyBlue,
      maroon: colors.maroon,
      white: colors.white,
    },
    info: {
      main: colors.primaryMain,
    },
  },
  typography: {
    fontFamily: 'Roboto, Sans-serif',
    h1: {
      fontSize: 45,
      fontWeight: 400,
      lineHeight: '53px',
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 75,
        lineHeight: '88px',
      },
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '29px',
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 32,
        lineHeight: '38px',
      },
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: 40,
        lineHeight: '47px',
      },
    },
    h3: {
      fontSize: 25,
      fontWeight: 400,
      lineHeight: '35px',
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 40,
        lineHeight: '45px',
      },
    },
    h4: {
      fontSize: 25,
      fontWeight: 400,
      lineHeight: '35px',
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 35,
        lineHeight: '41px',
      },
    },
    h5: {
      fontSize: 25,
      fontWeight: 700,
      lineHeight: '30px',
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: 30,
        lineHeight: '36px',
      },
    },
    subtitle1: {
      fontFamily: 'Impact',
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '24px',
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 25,
        lineHeight: '30px',
      },
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: 30,
        lineHeight: '36px',
      },
    },
    subtitle2: {
      fontFamily: 'Impact',
      fontSize: 15,
      lineHeight: 1.1,
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: 25,
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 34,
      },
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: 40,
      },
    },
    body1: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '24px',
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 25,
        lineHeight: '33px',
      },
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: 30,
        lineHeight: '45px',
      },
    },
    body2: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '24px',
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 25,
        lineHeight: '30px',
      },
    },
  },
  shadows: ['none', shadows.xs, shadows.s, 'none', 'none'],
} as unknown as IThemeOptions);

export default theme;
