// eslint-disable-next-line import/no-mutable-exports, @typescript-eslint/ban-types
let firebaseConfig: Object;
if (process.env.REACT_APP_RELEASE_CHANNEL === 'prod') {
  firebaseConfig = {
    apiKey: 'AIzaSyD9qTxxuJZdnSCQcFNR8BlFsR470Dw-CX0',
    authDomain: 'tada-games-prod.firebaseapp.com',
    projectId: 'tada-games-prod',
    storageBucket: 'tada-games-prod.appspot.com',
    messagingSenderId: '270434394497',
    appId: '1:270434394497:web:3595617cb1a6fd368bc880',
    measurementId: 'G-4T02H77M35',
  };
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyA10wOSAIR_V57bYlnTaFCfIShSb0UZoaM',
    authDomain: 'tada-games-staging.firebaseapp.com',
    projectId: 'tada-games-staging',
    storageBucket: 'tada-games-staging.appspot.com',
    messagingSenderId: '183353034662',
    appId: '1:183353034662:web:f32ae4aff68460323b3167',
    measurementId: 'G-Z489ZMREZV',
  };
}

export default firebaseConfig;
