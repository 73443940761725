import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ConfirmModal from '../ConfirmModal';
import messages from './messages';

interface RouterPromptProps {
  when: boolean;
  onConfirm: () => boolean;
  onCancel: () => boolean;
}

const RouterPrompt: React.FC<RouterPromptProps> = ({
  when,
  onConfirm,
  onCancel,
}) => {
  const history = useHistory();
  const intl = useIntl();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleConfirm = useCallback(async () => {
    if (onConfirm) {
      const canRoute = await Promise.resolve(onConfirm());
      if (canRoute) {
        history.block(() => {});
        history.push('/');
      }
    }
  }, [history, onConfirm]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <ConfirmModal
      open={showPrompt}
      message={intl.formatMessage(messages.confirmMessage)}
      onConfirm={handleConfirm}
      cancelButtonLabel={intl.formatMessage(messages.no)}
      confirmButtonLabel={intl.formatMessage(messages.yes)}
      onCancel={handleCancel}
    />
  ) : null;
};

export default RouterPrompt;
