import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import Box from '../Box';
import useStyles from './useStyles';

interface FlipperProps {
  isFlipped: boolean;
  frontContent: React.ReactNode;
  backContent: React.ReactNode;
}

const Flipper: React.FC<FlipperProps> = ({
  frontContent,
  backContent,
  isFlipped,
}) => {
  const classes = useStyles();
  const flipperRef = useRef(null);

  useEffect(() => {
    const rotateFrom = isFlipped ? '0' : '180';
    const rotateTo = isFlipped ? '180' : '0';

    gsap.from(flipperRef.current, {
      rotationX: rotateFrom,
    });

    gsap.to(flipperRef.current, {
      duration: 0.5,
      rotationX: rotateTo,
      ease: 'none',
    });
  }, [isFlipped]);

  return (
    <Box ref={flipperRef} className={classes.flipper} position="relative">
      <Box className={classes.front}>{frontContent}</Box>
      <Box width={1} /* position="absolute" top={0} */ className={classes.back}>
        {backContent}
      </Box>
    </Box>
  );
};

export default Flipper;
