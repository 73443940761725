import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmMessage: {
    id: 'Unknown.RouterPrompt.confirmMessage',
    defaultMessage: 'Are you sure you want to exit?',
  },
  yes: {
    id: 'Unknown.RouterPrompt.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'Unknown.RouterPrompt.no',
    defaultMessage: 'No',
  },
});
