import { defineMessages } from 'react-intl';

export default defineMessages({
  companyAddress: {
    id: 'Unknown.Footer.companyAddress',
    defaultMessage: 'Created by Tada Games 88 Lower Marsh, London SE1 7AB.',
  },
  emailText: {
    id: 'Unknown.Footer.emailText',
    defaultMessage: 'info@tada.games',
  },
  registration: {
    id: 'Unknown.Footer.registration',
    defaultMessage: 'Company reg no 10511594',
  },
  termsLinkText: {
    id: 'Unknown.Footer.termsLinkText',
    defaultMessage: 'Terms and Conditions',
  },
  privacyLinkText: {
    id: 'Unknown.Footer.privacyLinkText',
    defaultMessage: 'Privacy Policy',
  },
  cookiesLinkText: {
    id: 'Unknown.Footer.cookiesLinkText',
    defaultMessage: 'Cookies Policy',
  },
});
