import { makeStyles } from '@mui/styles';

export default makeStyles({
  innerWrapper: {
    background: 'linear-gradient(180deg, #080808 0%, #616161 100%)',
  },
  hinge: {
    background: 'linear-gradient(180deg, #4E4E4E 0%, #040404 100%)',
  },
  axis: {
    background:
      'linear-gradient(180deg, rgba(97,97,97, 0.75) 0%, rgba(97,97,97, 0.25) 100%)',
  },
  digit: {
    fontFamily: 'Impact',
    animation: `$squeeze 0.1s linear`,
  },
  '@keyframes squeeze': {
    '50%': {
      transform: 'scaleY(0)',
    },
  },
});
