import { makeStyles } from '@mui/styles';
import { ITheme } from '../../../common/theme';

export default makeStyles((theme: ITheme) => ({
  middleShadowMask: {
    filter: 'blur(4px)',
  },
  progress: ({ isWarning }: { isWarning: boolean; isLastSecond: boolean }) => ({
    color: isWarning ? theme.palette.common.red : theme.palette.common.orange,
  }),
  progressMask: ({ isLastSecond }) => ({
    transitionDuration: '.3s',
    transitionTimingFunction: isLastSecond
      ? 'cubic-bezier(.79,1,.75,.54)'
      : 'cubic-bezier(.79,2.75,.53,.54)',
  }),
  textAnimation: {
    animation: `$myEffect .3s cubic-bezier(.79,2.75,.53,.54)`,
  },
  '@keyframes myEffect': {
    '0%': {
      transform: 'scale(1.2)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  timer: {
    transform: 'scale(1)',
    transition: 'transform .7s cubic-bezier(.26,-0.65,1,.62)',
  },
  removeTimer: {
    transform: 'scale(0)',
  },
}));
