import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '../Box';
import Typography from '../Typography';
import Link from '../Link';
import messages from './messages';
import useStyles from './useStyles';

const Footer: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const footerLinks = [
    {
      title: messages.termsLinkText,
      href: '/terms-and-conditions',
    },
    {
      title: messages.privacyLinkText,
      href: '/privacy-policy',
    },
    {
      title: messages.cookiesLinkText,
      href: '/cookies-policy',
    },
  ];

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Typography variant="body2">
          <FormattedMessage {...messages.companyAddress} />
        </Typography>
        {isLarge && (
          <Typography variant="body2" mx={1}>
            |
          </Typography>
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={{ xs: 'column', sm: 'row' }}
          width={{ xs: 1, lg: 'auto' }}
          mt={{ xs: 1, lg: 0 }}
        >
          <Typography display="inline" variant="body2">
            <Link
              href="mailto:info@tada.games"
              underline="none"
              color="text.primary"
            >
              <FormattedMessage {...messages.emailText} />
            </Link>
          </Typography>
          {!isSmall && (
            <Typography variant="body2" display="inline" mx={1}>
              |
            </Typography>
          )}
          <Typography display={{ xs: 'block', sm: 'inline' }} variant="body2">
            <FormattedMessage {...messages.registration} />
          </Typography>
        </Box>
      </Box>
      {!isMobile && (
        <Box textAlign="center" lineHeight={1.25}>
          {footerLinks.map(({ title, href }, index) => {
            const isFirst = index === 0;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={index}>
                {!isFirst && (
                  <Typography display="inline" variant="body2" mx={1}>
                    |
                  </Typography>
                )}
                <NavLink className={classes.navLink} to={href}>
                  <FormattedMessage {...title} />
                </NavLink>
              </React.Fragment>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default Footer;
