import React from 'react';
import reactStringReplace from 'react-string-replace';
import Typography from '../../Unknown/Typography';

const replaceValues = (
  text: string | React.ReactNode[],
  values: { [key: string]: React.ReactNode },
): React.ReactNode => {
  const valuesToReplace = Object.keys(values).map((value) => `{${value}}`);
  let replacedText = text;

  const getComponentToReplace = (match: string) => {
    // eslint-disable-next-line no-useless-escape
    const key = match.replace(/[\{\}]/g, '');

    return (
      <Typography
        component="span"
        fontSize={{ xs: 'inherit' }}
        lineHeight={{ xs: 'inherit' }}
        color="secondary.main"
        whiteSpace="nowrap"
      >
        {values[key]}
      </Typography>
    );
  };

  valuesToReplace.forEach((value) => {
    replacedText = reactStringReplace(
      replacedText,
      value,
      getComponentToReplace,
    );
  });

  return replacedText;
};

export default replaceValues;
