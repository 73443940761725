import React, { useState, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Box from '../../Unknown/Box';
import Container from '../../Unknown/Container';
import Typography from '../../Unknown/Typography';
import MobileMenu from '../../Unknown/MobileMenu';
import IconButton from '../../Unknown/IconButton';
import getDocumentContent from './getDocumentContent';
import messages from './messages';
import menuItems from './menuItems';
import { ExitIcon } from '../../Unknown/Icons';

export interface LegalScreenProps {
  pageId: 'terms-and-conditions' | 'privacy-policy' | 'cookies-policy';
}

const LegalScreen: React.FC<LegalScreenProps> = ({ pageId }) => {
  const [content, setContent] = useState('');
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const titles = {
    'terms-and-conditions': messages.termsTitle,
    'privacy-policy': messages.privacyTitle,
    'cookies-policy': messages.cookiesTitle,
  };

  const handleExit = () => {
    history.push('/');
  };

  useEffect(() => {
    const setDocumentContent = async () => {
      const documentContent = await getDocumentContent(pageId);
      setContent(documentContent);
    };

    setDocumentContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box pt={{ md: 10 }}>
      <Box
        position="absolute"
        top={0}
        width={1}
        pt={{ md: 12, lg: 10 }}
        px={{ xl: 18 }}
      >
        <Container maxWidth="xl">
          <Box display="flex" justifyContent={{ md: 'flex-end' }}>
            {isMobile ? (
              <MobileMenu
                menuItems={menuItems}
                onHomeClick={handleExit}
                onExitClick={handleExit}
              />
            ) : (
              <IconButton onClick={handleExit} color="inherit">
                <Box pt={1} width={{ xs: 35, lg: 45 }}>
                  <ExitIcon />
                </Box>
              </IconButton>
            )}
          </Box>
        </Container>
      </Box>
      <Container maxWidth="xl">
        <Box px={{ xl: 18 }}>
          <Typography mt={{ xs: 2, md: 0 }} variant="h1" textAlign="center">
            <FormattedMessage {...titles[pageId]} />
          </Typography>
          <Markdown>{content}</Markdown>
        </Box>
      </Container>
    </Box>
  );
};

export default LegalScreen;
