import { makeStyles } from '@mui/styles';
import { ITheme } from '../../../common/theme';

export default makeStyles((theme: ITheme) => ({
  burgerIcon: {
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    marginLeft: theme.spacing(-2),
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textDecoration: 'none',
    color: theme.palette.common.darklyBlue,
  },
  menuButton: {
    justifyContent: 'space-between',
  },
}));
