import { defineMessages } from 'react-intl';

export default defineMessages({
  termsTitle: {
    id: 'Legal.LegalPage.termsTitle',
    defaultMessage: 'Terms and Conditions',
  },
  privacyTitle: {
    id: 'Legal.LegalPage.privacyTitle',
    defaultMessage: 'Privacy Policy',
  },
  cookiesTitle: {
    id: 'Legal.LegalPage.cookiesTitle',
    defaultMessage: 'Cookies Policy',
  },
  rulesLinkTitle: {
    id: 'Legal.LegalPage.rulesLinkTitle',
    defaultMessage: 'Rules of the Game',
  },
  cookiesLinkTitle: {
    id: 'Legal.LegalPage.cookiesLinkTitle',
    defaultMessage: 'Cookies Policy',
  },
  privacyLinkTitle: {
    id: 'Legal.LegalPage.privacyLinkTitle',
    defaultMessage: 'Privacy Policy',
  },
  termsLinkTitle: {
    id: 'Legal.LegalPage.termsLinkTitle',
    defaultMessage: 'Terms & Conditions',
  },
  homeLinkTitle: {
    id: 'Legal.LegalPage.homeLinkTitle',
    defaultMessage: 'Return to Home',
  },
});
