import { makeStyles } from '@mui/styles';

export default makeStyles({
  wrapper: ({ active }: { active: boolean }) => ({
    transition: 'all 0.3s ease-in-out',
    boxShadow: active ? '0 5px 15px rgba(255, 255, 255, 0.75)' : 'none',
    textShadow: active ? '0 5px 15px rgba(255, 255, 255, 1)' : 'none',
  }),
  mask: {
    background:
      'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 50%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.4) 100%)',
  },
  number: {
    opacity: 0.75,
  },
  animatedRocket: {
    animation: '$animatedRocket 1s ease',
  },
  '@keyframes animatedRocket': {
    '30%': {
      transform: 'scale(1.4)',
    },
    '40%': {
      transform: 'rotate(-20deg) scale(1.4)',
    },
    '50%': {
      transform: 'rotate(20deg) scale(1.4)',
    },
    '60%': {
      transform: 'rotate(-20deg) scale(1.4)',
    },
    '70%': {
      transform: 'rotate(0deg) scale(1.4)',
    },
    '100%': { transform: 'scale(1)' },
  },
});
