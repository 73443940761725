import Big from 'big.js';
import { QuestionComplexity, GameConfig } from '../../../../types';

const getFormattedRange = (
  complexity: QuestionComplexity,
  configByComplexity: GameConfig['byComplexity'],
): string => {
  const easyRangeStart = 1;
  const mediumRangeStart = new Big(easyRangeStart)
    .plus(configByComplexity.easy?.questionsCount || 0)
    .toNumber();
  const hardRangeStart = new Big(mediumRangeStart)
    .plus(configByComplexity.medium?.questionsCount || 0)
    .toNumber();
  const extraHardRangeStart = new Big(hardRangeStart)
    .plus(configByComplexity.hard?.questionsCount || 0)
    .toNumber();
  const easyRangeEnd = new Big(easyRangeStart)
    .plus(configByComplexity.easy?.questionsCount || 0)
    .minus(1)
    .toNumber();
  const mediumRangeEnd = new Big(hardRangeStart).minus(1).toNumber();
  const hardRangeEnd = new Big(hardRangeStart)
    .plus(configByComplexity.hard?.questionsCount || 0)
    .minus(1)
    .toNumber();
  const extraHardRangeEnd = new Big(extraHardRangeStart)
    .plus(configByComplexity.extra_hard?.questionsCount || 0)
    .minus(1)
    .toNumber();

  const rangeByComplexity = {
    easy: `${easyRangeStart}-${easyRangeEnd}`,
    medium: `${mediumRangeStart}-${mediumRangeEnd}`,
    hard: `${hardRangeStart}-${hardRangeEnd}`,
    extra_hard: `${extraHardRangeStart}-${extraHardRangeEnd}`,
  };

  return rangeByComplexity[complexity];
};

export default getFormattedRange;
