import { Box, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const NotFoundScreen: React.FC = () => (
  <Box
    height="100vh"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <Typography variant="h1">
      <FormattedMessage {...messages.title} />
    </Typography>
  </Box>
);

export default NotFoundScreen;
