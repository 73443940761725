import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

export default makeStyles((theme: Theme) => ({
  paper: {
    height: 'auto',
    borderWidth: 5,
    borderStyle: 'solid',
    borderColor: theme.palette.text.primary,
    borderRadius: 20,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  image: {
    width: '100%',
  },
}));
